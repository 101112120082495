<template>
  <div id="top-bar-component">
    <v-system-bar window class="first-top-bar px-xs-2 px-sm-5 px-md-10">

      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        @click="toggleSidemenu()"
        class="mx-1"
        height="48px"
        width="48px"
        elevation=0
        large icon tile
      >
        <v-icon class="mr-0" size="28px">mdi-view-split-horizontal</v-icon>
      </v-btn>

      <router-link v-bind:to="getHeaderLink" class="main-logo mx-1">
        <img v-if="this.getDarkMode" src="../../assets/images/tw-main-logo-dark.png" />
        <img v-if="!this.getDarkMode" src="../../assets/images/tw-main-logo-light.png" />
      </router-link>

      <v-spacer></v-spacer>

      <v-btn
        v-if="!isTenantSubscriptionExpired"
        color="primary"
        class="mx-1"
        elevation=0
        to="/"
      >
        <v-icon>mdi-backburger</v-icon>
        <span class="hidden-xs">
          {{$t('layouts.main.backToApp')}}
        </span>
      </v-btn>

      <NotificationsMenu v-if="!isTenantSubscriptionExpired">
      </NotificationsMenu>

      <BeeUserMenu></BeeUserMenu>

    </v-system-bar>
  </div>
</template>

<script>
import BeeUserMenu from '../mainLayout/components/topBars/BeeUserMenu.vue';
import NotificationsMenu from '../mainLayout/components/topBars/notifications/NotificationsMenu.vue'
import router from "@/routes/router";

export default {
  name: "TopBar",
  components: {
    BeeUserMenu,
    NotificationsMenu,
  },
  methods: {
    toggleSidemenu() {
      const TOGGLESIDEMENU = this.$store.getters.keywords.MAIN.TOGGLESIDEMENU;
      this.$store.commit(TOGGLESIDEMENU);
    },
  },
  computed: {
    getDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    },
    getHeaderLink: function() {
      // if (this.accessPoints && this.accessPoints.length > 0) {
      //   return '/node/' + this.accessPoints[0].id;
      // }

      return '/console/index';
    },
    isTenantSubscriptionExpired() {
      const TENANTISEXPIRED  = router.app.$store.getters.keywords.AUTH.TENANTISEXPIRED;
      return this.$store.getters[TENANTISEXPIRED];
    },
  }
}
</script>

<style>
</style>