import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    labels: [],
    categories: [],
    activeCategoryId: null,
  },
  getters: {
    getCategories: function(state) {
      return state.categories;
    },
    getLabels: function(state) {
      return state.labels;
    },
    getActiveCategoryId: function(state) {
      return state.activeCategoryId;
    }
  },
  mutations: {
    setCategories: function(state, categories) {
      state.categories = categories;
    },
    setLabels: function(state, labels) {
      state.labels = labels;
    },
    resetLabels: function(state) {
      state.labels = [];
    },
    setActiveCategoryId: function(state, categoryId) {
      state.activeCategoryId = categoryId;
    }
  },
  actions: {
    refreshLabels: function(context, payload) {
      return Api.label.getLabels(payload)
        .then(function(response) {
          context.commit('setLabels', response.data);
        });
    },
    refreshCategories: function(context, payload) {
      return Api.label.getCategories(payload)
        .then(function(response) {
          context.commit('setCategories', response.data);
          return response.data;
        });
    },
    getCategories: function(context, payload) {
      return Api.label.getCategories(payload)
        .then(function(response) {
          return response.data;
        });
    },
    createCategory: function(context, payload) {
      return Api.label.createCategory(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateCategory: function(context, payload) {
      return Api.label.updateCategory(payload)
        .then(function(response) {
          return response.data;
      });
    },
    deleteCategory: function(context, payload) {
      return Api.label.deleteCategory(payload)
        .then(function(response) {
          return response.data;
        });
    },
    createLabel: function(context, payload) {
      return Api.label.createLabel(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateLabel: function(context, payload) {
      return Api.label.updateLabel(payload)
        .then(function(response) {
          return response.data;
        });
    },
    deleteLabel: function(context, payload) {
      return Api.label.deleteLabel(payload)
        .then(function(response) {
          return response.data;
        });
    },
    fetchLabels(context, payload) {
      return Api.label.getLabels(payload)
        .then(function(response) {
          return response.data;
        });
    },
    addMetaLabels(context, payload) {
      return Api.label.addMetaLabels(payload)
        .then(function(response) {
          return response.data;
        });
    },
  }
}
