<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :disabled="disabled"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-uploadSubtitles-activator">
        <slot></slot>
      </div>
    </template>

    <v-card v-cloak v-on:drop.prevent="droppedFiles" v-on:dragover.prevent data-cy="dialog-uploadSubtitles-modal">
      <v-card-title class="primary d-flex justify-space-between" data-cy="dialog-uploadSubtitles-title">
        {{$t('routes.pod.menuSubtitles.uploadSubtitleMenuItem')}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable mt-4">
        <v-list class=" text-left upload-list_list"  flat dense >
          <v-list-item-group color="primary">
            <v-list-item
              v-for="item in selectedFiles"
              :key="item.meta.fileuuid"
              class="upload-list-item_item"
            >

              <v-list-item-content>
                <v-list-item-title>
                  <v-tooltip bottom z-index="1200">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{item.meta.filename}}</span>
                    </template>
                    <span>{{getFilenameTooltip(item)}}</span>
                  </v-tooltip>
                </v-list-item-title>

                <v-list-item-subtitle v-show="item.status == STATUSES.UPLOADING" class="upload-list-item_subtitle">
                  <v-progress-linear v-bind:value="item.progress.percentage"></v-progress-linear>
                </v-list-item-subtitle>

                <v-list-item-subtitle v-bind:class="{error: item.status == STATUSES.ERROR}" class="upload-list-item_subtitle">
                  {{ getItemSubtitle(item) }}
                </v-list-item-subtitle>
                <!-- /Subtitle elements -->
              </v-list-item-content>

              <!-- Icons at the end -->
              <v-list-item-icon
                v-show="showItemCancelButton(item)"
                v-on:click="removeSelectedFile(item)"
                class="ml-0">
                  <v-icon  color="primary">mdi-close</v-icon>
              </v-list-item-icon>

            </v-list-item>
          </v-list-item-group>


          <v-list-item-group color="primary">
            <v-list-item v-on:click="browseFiles" class="upload-list-action">
              <v-list-item-icon class="mr-1">
                <v-icon color="primary">mdi-attachment</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title color="primary">{{$t('menus.uploadFiles.browseFiles')}}</v-list-item-title>
                <v-list-item-subtitle class="upload-list-item_subtitle" color="primary">
                  {{$t('menus.uploadFiles.clickToAddFiles')}}
                  <input type="file"
                         ref="fileInput"
                         v-on:change="filesSelected"
                         style="display: none"
                         multiple
                         accept=".srt, .vtt"
                         data-cy="dialog-uploadSubtitles-inputFiles">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-on:click="browseFolders" class="upload-list-action">
              <v-list-item-icon class="mr-1">
                <v-icon color="primary">mdi-attachment</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title color="primary">{{$t('menus.uploadFiles.browseFolders')}}</v-list-item-title>
                <v-list-item-subtitle class="upload-list-item_subtitle" color="primary">
                  {{$t('menus.uploadFiles.clickToAddFolders')}}
                  <input type="file" ref="foldersInput" v-on:change="filesSelected" style="display: none" multiple webkitdirectory data-cy="dialog-uploadSubtitles-inputFolders">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-show="hasAddedFiles"
          v-on:click="uploadAllFiles"
          color="primary"
          data-cy="dialog-uploadSubtitles-uploadAllButton"
        >
          {{$t('menus.uploadFiles.uploadAll')}}
        </v-btn>

        <v-btn text @click="dialog = false" data-cy="dialog-uploadSubtitles-closeButton">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import Vue from 'vue'
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "uploadSubtitlesDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      typesModel: {},   // set's the type of the assets when uploading asset
    };
  },
  watch: {
    getMainviewDropfilesEvent(event) {
      if (event) {
        this.droppedFiles(event);
        this.dialog = true;

        const SETMAINVIEWDROPFILESEVENT = this.$store.getters.keywords.UPLOAD.SETMAINVIEWDROPFILESEVENT;
        this.$store.dispatch(SETMAINVIEWDROPFILESEVENT, null);
      }
    },
    dialog(newValue) {
      if (!newValue) {
        const RESETFILES = this.$store.getters.keywords.UPLOAD.RESETFILES;
        this.$store.commit(RESETFILES);
      }
    }
  },
  methods: {
    removeSelectedFile: function(item) {
      const REMOVEFILE = this.$store.getters.keywords.UPLOAD.REMOVEFILE;
      this.$store.dispatch(REMOVEFILE, item);
    },
    uploadAllFiles: async function() {
      const FILES = this.$store.getters.keywords.UPLOAD.FILES;
      const files = this.$store.getters[FILES];

      const fileTypes = new Map();
      for (let i = 0; i < files.length; i++) {
        const fileId = files[i].meta.fileuuid;
        const fileExtension = files[i].meta.filename.split('.').pop().toLowerCase();

        let fileType;
        if (fileExtension === 'srt') {
          fileType = 7;
        } else if (fileExtension === 'vtt') {
          fileType = 6;
        } else {
          fileType = 12; // other
        }

        fileTypes.set(fileId, fileType);
      }

      const payload = {
        fileTypes,
      };

      const SETASSETTYPES = this.$store.getters.keywords.UPLOAD.SETASSETTYPES;
      this.$store.commit(SETASSETTYPES, payload);

      const self = this;

      const UPLOADALL = this.$store.getters.keywords.UPLOAD.UPLOADALL;
      this.$store.dispatch(UPLOADALL)
        .then(function(pod) {
          const REFRESHSUBTITLES = self.$store.getters.keywords.PLAYER.REFRESHSUBTITLES;
          return self.$store.dispatch(REFRESHSUBTITLES, pod);
        })
        .then(function() {
          self.dialog = false;
        })
        .catch(function(error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            const apiError = error.response.data.error.message;
            if (typeof apiError == 'object') {
              self.errorTranslatable = apiError;
            } else {
              self.errorMessage = apiError;
            }
          } else {
            self.errorMessage = "Error!";
            console.log(error);   // unhandled error
          }
        })
        .finally(function() {
          self.$emit('subtitleCreated');
        });
    },
    browseFiles: function() {
      this.$refs.fileInput.click();
    },
    browseFolders: function() {
      this.$refs.foldersInput.click();
    },
    filesSelected: function(event) {
      if (event.target.files && event.target.files.length > 0) {
        const ADDFILES = this.$store.getters.keywords.UPLOAD.ADDFILES;
        let payload = {
          podID: this.pod ? this.pod.id : null,
          fileList: this.cleanFiles(event.target.files),
          nodeID: null
        };
        this.$store.dispatch(ADDFILES, payload);
        this.$refs.fileInput.value = null;
        this.$refs.foldersInput.value = null;
      }
    },
    droppedFiles: async function(event) {
      let filesList = [];
      let dropItems = [];

      if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          dropItems.push(event.dataTransfer.items[i].webkitGetAsEntry());
        }
      }

      // the async read of files is on a separate step to fix an issue with chrome
      for (let i = 0; i < dropItems.length; i++) {
        if (dropItems[i]) {
          let foundFiles = await this.readFilesystemItem(dropItems[i], '');
          filesList = filesList.concat(foundFiles);
        }
      }

      if (filesList.length > 0) {
        const ADDFILES = this.$store.getters.keywords.UPLOAD.ADDFILES;
        let payload = {
          podID: this.pod ? this.pod.id : null,
          fileList: this.cleanFiles(filesList),
        };

        this.$store.dispatch(ADDFILES, payload);
        this.$refs.fileInput.value = null;
        this.$refs.foldersInput.value = null;
      }
    },
    readFilesystemItem: async function(dropItem, fullPath) {
      let self = this;


      return new Promise(function(resolve, reject) {
        reject;

        // Return the file itself
        if (dropItem.isFile) {
          dropItem.file(function(fsFile) {

            // Fix the webkitRelativePath for the chrome browsers
            let filePath = fullPath + "/" + fsFile.name;
            if (filePath.startsWith('/')) {
              filePath = filePath.replace('/', '');
            }
            Object.defineProperty(fsFile, 'webkitRelativePath', {get: function() {return filePath}});

            resolve(fsFile);
          });
        }

        // Read the other directories recursively
        if (dropItem.isDirectory) {
          let dirReader = dropItem.createReader();
          dirReader.readEntries(async function(entries) {
            let filesArray = [];
            for (let i = 0; i < entries.length; i++) {
              let fileReaded = await self.readFilesystemItem(entries[i], dropItem.fullPath);
              filesArray = filesArray.concat(fileReaded);
            }

            resolve(filesArray);
          });
        }

      });
    },
    cleanFiles: function (files) {
      const allowedExtensions = ['srt', 'vtt'];

      const filesArray = Array.from(files);

      return filesArray.filter(file => {
        const match = /\.([0-9a-z]+)$/i.exec(file.name);

        const extension = match ? match[1].toLowerCase() : '';
        return allowedExtensions.includes(extension);
      });
    }
  },
  computed: {
    selectedFiles: function() {
      const FILES = this.$store.getters.keywords.UPLOAD.FILES;
      const storedFiles = this.$store.getters[FILES];
      let selectedFiles = [];

      for (let i = 0; i < storedFiles.length; i++) {
        if (this.pod && storedFiles[i].podid && storedFiles[i].podid === this.pod.id) {
          selectedFiles.push(storedFiles[i]);
          continue;
        }
      }

      return selectedFiles;
    },
    STATUSES: function() {
      const STATUSES = this.$store.getters.keywords.UPLOAD.STATUSES;
      return this.$store.getters[STATUSES];
    },
    USER: function() {
      const GETUSER = this.$store.getters.keywords.AUTH.GETUSER;
      return this.$store.getters[GETUSER];
    },
    hasAddedFiles: function() {
      for(let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].status === this.STATUSES.ADDED) {
          return true;
        }
      }

      return false;
    },
    showItemTeamonlyCheckbox: function() {
      return function(item) {
        return  !this.pod &&
                item.status == this.STATUSES.ADDED &&
                this.$canBelongstoTeam();
      }
    },
    showItemCancelButton: function() {
      return function(item) {
        return  item.status == this.STATUSES.ADDED ||
                item.status == this.STATUSES.FINISHED ||
                item.status == this.STATUSES.ERROR;
      }
    },
    getItemSubtitle: function() {
      return function(item) {
        let text = "";
        switch (item.status) {
          case this.STATUSES.ADDED: text = "("+ Vue.filter('prettyBytes')(item.meta.filesize, 0, false) +")"; break;
          case this.STATUSES.WAITING: text = this.$t('menus.uploadFiles.statusWaiting'); break;
          case this.STATUSES.UPLOADING:
              text = "" + Vue.filter('prettyBytes')(item.progress.uploaded, 0, false);
              text += " of " + Vue.filter('prettyBytes')(item.meta.filesize, 0, false);
              break;
          case this.STATUSES.FINISHED: text = this.$t('menus.uploadFiles.statusFinished'); break;
          case this.STATUSES.ERROR: text = item.errorMessage ? this.$t(item.errorMessage) : this.$t('menus.uploadFiles.statusError'); break;
          default: text = this.$t('menus.uploadFiles.statusUnknown'); break;
        }
        return text;
      }
    },
    getFilenameTooltip: function() {
      return function(item) {
        if (item && item.meta && item.meta.webkitRelativePath) {
          return item.meta.webkitRelativePath;
        }
        return item.meta.filename;
      }
    },
    getViewPod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    needsToRefreshSubdirectories: function() {
      if (this.pod || this.getViewPod) {
        return false;
      }

      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i] &&
            this.selectedFiles[i].file &&
            this.selectedFiles[i].file.webkitRelativePath &&
            this.selectedFiles[i].file.webkitRelativePath.indexOf('/' >= 0)) {
              return true;
            }
      }
      return false;
    },

    getMainviewDropfilesEvent() {
      const GETMAINVIEWDROPFILESEVENT = this.$store.getters.keywords.UPLOAD.GETMAINVIEWDROPFILESEVENT;
      return this.$store.getters[GETMAINVIEWDROPFILESEVENT];
    }
  },
}
</script>

<style>
.upload-list_list {
  width: 100%;
  border: 1px solid #3dabff      !important;
  border-radius: 6px !important;
}

.theme--dark.upload-list_list {
  background-color: #2f3640 !important;
}
.theme--light.upload-list_list {
  background-color: #e6ecf1 !important;
}


.upload-list-item_subtitle {
  white-space: unset !important;
  text-overflow: unset !important;
  overflow: unset !important;
}

.upload-list-item_item {
  margin: 4px;
  border: 1px #be9323 inset;
  border-radius: 6px;
}

.upload-list_list .v-list-item__title {
  font-size: large !important;
}

.upload-list_list .v-list-item__subtitle {
  font-size: small !important;
  font-style: italic !important;
  margin-top: 3px !important;
}

.upload-list_list .upload-list-action {
  margin-top: 10px;
}
</style>
