// import ... API
// import Vue from 'vue';
import {
  signIn as AuthSignIn,
  signOut as AuthSignOut,
  confirmSignUp as AuthConfirmSignUp,
  confirmSignIn as AuthConfirmSignIn,
  setUpTOTP as AuthSetUpTOTP,
  verifyTOTPSetup as AuthVerifyTOTPSetup,
  fetchMFAPreference as AuthFetchMFAPreference,
  getCurrentUser as AuthGetCurrentUser,
  updateMFAPreference as AuthUpdateMFAPreference,
  fetchAuthSession as AuthFetchAuthSession,
  resetPassword as AuthResetPassword,
  confirmResetPassword as AuthConfirmResetPassword,
  updatePassword as AuthUpdatePassword,
  // fetchUserAttributes as AuthFetchUserAttributes,
} from 'aws-amplify/auth'
import Api from '../../api/Api'
import Helper from '@/components/helper';

export default {
  namespaced: true,
  state: {
    user: null,
    dbUser: null,

    tenants: [],
    activeTenantId: window.localStorage.activetenantid || null,
    rootNodeId: window.localStorage.contextnodeid || null,

    forceLogout: false,
  },
  getters: {
    user: function (state) {
      return state.user;
    },
    dbUser: function (state) {
      return state.dbUser;
    },
    getTenants: function(state) {
      return state.tenants;
    },
    getActiveTenant: function(state) {
      return state.tenants.find(x => x.id === state.activeTenantId);
    },
    getActiveTenantId: function(state) {
      return state.activeTenantId;
    },
    getAccesspoints: function(state) {
      let accesspoints = [];

      for (const tenantItem of state.tenants) {
        for (const accesspoint of tenantItem.accessPoints) {
          accesspoints.push(accesspoint);
        }
      }

      return accesspoints;
    },
    getAccessPointIds: function(state, getters) {
      let accesspointIDs = [];
      for (const accesspoint of getters.getAccesspoints) {
        accesspointIDs.push(accesspoint.node.id);
      }
      return accesspointIDs;
    },
    getActiveAccesspoint: function(state, getters) {
      return getters.getAccesspoints.find(x => x.node.id === state.rootNodeId);
    },

    tenantIsExpired: function(state, getters) {
      if (getters?.getActiveTenant?.subscription?.subscriptionEnd) {
        if (getters.getActiveTenant.subscription.subscriptionEnd.minutes > 0) {
          return false;
        }

        return true;
      }

      return false;
    },
    subscriptionExpireTimestamp: function(state, getters) {
      if (getters.getActiveTenant &&
          getters.getActiveTenant.subscription &&
          getters.getActiveTenant.subscription.subscriptionEnd) {
        return parseInt(getters.getActiveTenant.subscription.subscriptionEnd.seconds * 1000);
      }
      return false;
    },

    forceLogout: function(state) {
      return state.forceLogout;
    },
    rootNodeId: function(state) {
      return state.rootNodeId;
    //   /*if (state?.dbUser?.groupnodes[0]?.nodeid) {
    //     return state.dbUser.groupnodes[0].nodeid;
    //   }*/

    //   console.log('state?.dbUser', state?.dbUser);
    //   console.log('root node ID', state?.dbUser?.tenants[0]?.accessPoint?.node?.id);

    //   if (state?.dbUser?.tenants[0]?.accessPoint?.node?.id) {
    //     return state?.dbUser?.tenants[0]?.accessPoint?.node?.id;
    //   }

    //   return null;
    },
  },
  mutations: {
    updateUser: function(state, user) {
      if (user && user.sub) {
        user.uuid = user.sub;
      }
      state.user = user;
    },
    updateDBUser: function(state, dbUser) {
      state.dbUser = dbUser;
    },
    setTenants: function(state, tenants) {
      state.tenants = tenants;
    },
    setActiveTenantId: function(state, tenantId) {
      window.localStorage.activetenantid = tenantId;
      state.activeTenantId = tenantId;
    },
    setRootNodeId: function(state, rootNodeId) {
      window.localStorage.contextnodeid = rootNodeId;
      state.rootNodeId = rootNodeId;
    },
    updateForceLogout: function(state, forceLogout) {
      state.forceLogout = forceLogout;
    },
    updateDBUserProperty: function(state, payload) {
      if (state.dbUser && state.dbUser[payload.property]) {
        state.dbUser[payload.property] = payload.value;
      }
    },
    updateUserSettingValue: function(state, payload) {
      if (state.dbUser && state.dbUser.settings && (payload.name in state.dbUser.settings)) {
        state.dbUser.settings[payload.name] = payload.value.toString();
      }
    },
  },
  actions: {
    signIn: async function(context, loginParams) {
      try {
        // just in case that there is a user logged in
        await AuthSignOut();
      } catch (error) {
        console.log("Try to logout before login", error);
      }

      const signInResponse = await AuthSignIn(loginParams);
      context.dispatch('afterSignIn', signInResponse);
      return signInResponse;
    },
    async afterSignIn(context, signInResponse) {
      if (signInResponse.isSignedIn) {
        const REFRESHCOGNITO = context.rootGetters.keywords.AUTH.REFRESHCOGNITO;
        return context.dispatch(REFRESHCOGNITO, null, {root: true});

        // const session = await AuthFetchAuthSession();
        // const cognitoAttributes = await AuthFetchUserAttributes();

        // console.log("User attributes", cognitoAttributes);
        // console.log("sessions", session);

        // if (cognitoAttributes && Helper.isFCPMessageHandler('setUser')) {
        //   const userObject = JSON.stringify({action: "setUser", userID: cognitoAttributes.sub});
        //   Helper.invokeFCPMessageHandler('setUser', userObject);
        // }

        // context.commit('updateUser', cognitoAttributes);
      }
    },

    signOut: function(context) {
      // unsubscribe from notifications
      const UNSUBSCRIBE = context.rootGetters.keywords.NOTIFICATION.UNSUBSCRIBE;

      return AuthSignOut()
      .then(function () {
        context.commit('updateUser', null);
        context.dispatch(UNSUBSCRIBE, null, {root: true});

        if (Helper.isFCPMessageHandler('signOutUser')) {
          const signOutObject = JSON.stringify({action: "signOutUser"});
          Helper.invokeFCPMessageHandler('signOutUser', signOutObject);
        }
      });
    },

    confirmSignUp: function(context, params) {
      return AuthConfirmSignUp(params.email, params.code);
    },

    setupTOTP: function() {
      return AuthSetUpTOTP();
    },
    verifyTOTPToken: function(context, payload) {
      return AuthVerifyTOTPSetup(payload);
    },
    enableTOTPMFA: function() {
      // return AuthGetCurrentUser()
      // .then(function(user) {
        return AuthUpdateMFAPreference({ totp: 'ENABLED' });
      // });
    },
    disableTOTPMFA: function() {
      // return AuthGetCurrentUser()
      // .then(function(user) {
        return AuthUpdateMFAPreference({ totp: 'DISABLED' });
      // });
    },
    fetchMFAPreference() {
      return AuthFetchMFAPreference()
    },
    confirmTOTP: async function(context, code) {
      const signInResponse = await AuthConfirmSignIn({
        challengeResponse: code,
        // options: params.options,
      });
      context.dispatch('afterSignIn', signInResponse);
      return signInResponse;
    },

    refreshCognitoUser: function(context) {
      AuthFetchAuthSession()
      .then(function(session) {
        // console.log("Auth session", session);
        if (session && session.tokens) {
          if (Helper.isFCPMessageHandler('refreshUser')) {
            let idToken = session.tokens.idToken.toString();
            let accessToken = session.tokens.accessToken.toString();
            const sessionObject = JSON.stringify({action: "refreshUser", idToken: idToken, accessToken: accessToken});
            Helper.invokeFCPMessageHandler('refreshUser', sessionObject);
          }
        }
      })
      .catch(function() {
        // user not authenticated...
        if (window.webkit) {
          delete window.webkit;
        }
      });


      return AuthGetCurrentUser()
      .then(function(user) {
        // console.log("AuthGetCurrentUser", user);
        if (!user || !user.userId) {
          throw "User not authenticated!";
        }

        if (Helper.isFCPMessageHandler('setUser')) {
          const userObject = JSON.stringify({action: "setUser", userID: user.userId});
          Helper.invokeFCPMessageHandler('setUser', userObject);
        }

        context.commit('updateUser', user);
        return user;
      });
    },
    refreshDBUser: async function(context) {
      return Api.user.getMe()
      .then(function(response) {
        // The user's abilities are stored in the tenant's access point group.
        // It does not come with the user object anymore.
        const user = response.data.user;
        // user.abilities = firstTenantData.accessPoint.group.abilities;
        // user.abilities = response.data.tenants[0].accessPoints[0].group.abilities;
        // user.tenants = response.data.tenants;

        // store the abilities in the local storage
        // they are loaded up in App.vue
        // and then refreshed in MainLayout and ConsoleLayout
        // window.localStorage.abilities = JSON.stringify(response.data.user.abilities);
        window.localStorage.settings = JSON.stringify(response.data.user.settings);
        window.localStorage.languageInterface = response.data.user.settings.language;

        context.commit('updateDBUser', response.data.user);
        context.commit('setTenants', response.data.tenants);

        return user;
      })
      .catch(function(e) {
        console.log("Error while refreshing DB user information:", e);
      });
    },
    saveSettings: function(context, payload) {
      const userID = context.getters.dbUser.id;

      return Api.user.saveSettings(userID, payload)
      .then(function() {
        return context.dispatch('refreshDBUser');
      });
    },

    getCurrentSession: function() {
      return AuthFetchAuthSession();
    },

    forgotPassword: function(context, email) {
      return AuthResetPassword({username: email});
    },
    forgotPasswordSubmit: function(context, params) {
      return AuthConfirmResetPassword({
        username: params.email,
        newPassword: params.password,
        confirmationCode: params.code,
      });
    },
    changePassword: function(context, params) {
      return AuthUpdatePassword({
        oldPassword: params.oldPassword,
        newPassword: params.newPassword,
      });
    },
    completePassword: async function(context, params) {
      const signInResponse = await AuthConfirmSignIn({
        challengeResponse: params.password,
        options: params.options,
      });
      context.dispatch('afterSignIn', signInResponse);
      return signInResponse;
    },

    setRootNodeId: function(context, nodeId) {
      if (!context.getters.getAccessPointIds || context.getters.getAccessPointIds.indexOf(nodeId) < 0) {
        return;
      }

      for (let i = 0; i < context.getters.getTenants.length; i++) {
        for (let o = 0; o < context.getters.getTenants[i].accessPoints.length; o++) {
          if (context.getters.getTenants[i].accessPoints[o].node.id === nodeId) {
            context.commit('setActiveTenantId', context.getters.getTenants[i].id);
            context.commit('setRootNodeId', context.getters.getTenants[i].accessPoints[o].node.id);
            return;
          }
        }
      }
    },

    createFreeTrial: function(context, payload) {
      return Api.common.createFreeTrial(payload);
    },
    salesmanCreatesTenant: function(context, payload) {
      return Api.common.salesmanCreatesTenant(payload);
    },
    disableUser: function(context) {
      context.commit('setActiveTenantId', null);
      context.commit('setRootNodeId', null);
      context.commit('updateForceLogout', true);
    },
  },
};
