// src/routes/router.js

// import Vue from 'vue'
import VueRouter from 'vue-router'

// import PodIndex from './pods/PodIndex'
// import NodesIndex from './nodes/NodesIndex'
// import UsersIndex from './users/UsersIndex'
// import UsageUser from './usage/UsageUser'
// import UsageTenant from './usage/UsageTenant'
// import UsageIndex from './usage/UsageIndex'
// import SearchIndex from './search/SearchIndex'
// import NotificationsIndex from './notifications/NotificationsIndex'

// import AccesslinkIndex from './accesslink/AccesslinkIndex'
// import UploadlinkIndex from './uploadlink/UploadlinkIndex'
// import EmbeddedIndex from './embedded/EmbeddedIndex'

// These consts are also used in the external access link route
const MAIN_LAYOUT = 'MainLayout';
const LOGIN_LAYOUT = 'LoginLayout';
const CONSOLE_LAYOUT = 'ConsoleLayout';
const LOADING_LAYOUT = 'LoadingLayout';
// const EXTERNAL_LAYOUT = 'ExternalLayout';
const BLANK_LAYOUT = 'BlankLayout';

const routes = [
  // { path: '/',                        component: Index,                 meta: { layout: MAIN_LAYOUT,    requiresAuth: true} },
  { path: '/soon', component: () => import('./default/SoonPage.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/login', component: () => import('./account/AccountLogin.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/otp', component: () => import('./account/AccountLoginOTP.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/enable-mfa',component: () => import('./account/AccountEnableMFA.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: true} },
  { path: '/account/external', component: () => import('./account/AccountLoginExternal.vue'), meta: { layout: LOGIN_LAYOUT,  requiresAuth: false} },

  { path: '/account/forgot-password', component: () => import('./account/ForgotPassword.vue'),        meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/reset-password/:email?/:code?', component: () => import('./account/ResetPassword.vue'),  meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/reset-temporary-password', component: () => import('./account/ResetTemporaryPassword.vue'),  meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/verify/:email?', component: () => import('./account/AccountVerify.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/complete-password', component: () => import('./account/CompletePassword.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

  { path: '/account/bandwidth-exceeded', component: () => import('./account/BandwidthExceeded.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/tenant-expired', component: () => import('./account/TenantExpired.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: true} },
  { path: '/account/select-subscription',component: () => import('./account/SelectSubscription.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

  { path: '/account/logout', component: () => import('./account/AccountLogout.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: true} },
  { path: '/account/forced-logout', component: () => import('./account/ForcedLogout.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

  // US #12434 - Temporary disable account creation
  // { path: '/account-create',  component: () => import('./account/AccountSignUp.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account-create-success', component: () => import('./account/AccountSignedUp.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },
  { path: '/account/salesman',  component: () => import('./account/AccountSalesman.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

  { path: '/tenant/email', component: () => import('./tenant/ValidateTenantEmail.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false}},


  { path: '/', component: () => import('./default/Index.vue'), meta: { layout: MAIN_LAYOUT,    requiresAuth: true} },
  { path: '/node/:id', component: () => import('./nodes2/NodesIndex.vue'), meta: { layout: MAIN_LAYOUT,   requiresAuth: true}},
  { path: '/pod/:id', component: () => import('./pods2/PodIndex.vue'), meta: {layout: MAIN_LAYOUT, requiresAuth: true}},
  { path: '/search', component: () => import('./search/SearchIndex.vue'), meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},


  { path: '/console/index', component: () => import('./console/account/AccountIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/profile/mfa', component: () => import('./console/account/ProfileMFA.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/profile/notifications', component: () => import('./console/account/ProfileNotifications.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},

  { path: '/console/users', component: () => import('./console/users/UsersIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/users/permissions', component: () => import('./console/users/UserPermissions.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/subscriptions', component: () => import('./console/subscriptions/SubscriptionsIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/subscription-plans', component: () => import('./console/subscriptions/SubscriptionPlansIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/subscription-link', component: () => import('./console/subscriptions/SubscriptionLink.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/branding', component: () => import('./console/branding/BrandingIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/contacts', component: () => import('./console/contacts/ContactsIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/usage', component: () => import('./console/usage/UsageIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/system-usage/users/:id', component: () => import('./console/systemusage/UsageUser.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/system-usage/tenants/:id', component: () => import('./console/systemusage/UsageTenant.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/system-usage', component: () => import('./console/systemusage/UsageIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/tenants', component: () => import('./console/tenants/TenantsIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},
  { path: '/console/labels', component: () => import('./console/labels/LabelsIndex.vue'), meta: {layout: CONSOLE_LAYOUT, requiresAuth: true}},

  // { path: '/node',                    component: NodesIndex,            meta: { layout: MAIN_LAYOUT,    requiresAuth: true} },
  // { path: '/node/:id',                component: NodesIndex,            meta: { layout: MAIN_LAYOUT,    requiresAuth: true} },
  // { path: '/pod/:id',                 component: PodIndex,              meta: { layout: MAIN_LAYOUT,    requiresAuth: true} },

  // { path: '/usage',                   component: UsageIndex,            meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},
  // { path: '/usage/user/:id',          component: UsageUser,             meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},
  // { path: '/usage/tenant/:id',        component: UsageTenant,           meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},

  // { path: '/users',                   component: UsersIndex,            meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},
  // { path: '/notifications',           component: NotificationsIndex,    meta: { layout: MAIN_LAYOUT,    requiresAuth: true}},

  // external view of a pod
  { path: '/reviewaccess/:code', component: () => import('./accesslink2/AccesslinkIndex.vue'), meta: { layout: LOADING_LAYOUT, requiresAuth: false }},
  { path: '/reviewaccess/:code/login', component: () => import('./accesslink2/AccesslinkLogin.vue'), meta: { layout: LOGIN_LAYOUT, requiresAuth: false }},
  { path: '/reviewaccess/:code/view', component: () => import('./accesslink2/AccessLinkListView.vue'), meta: { layout: BLANK_LAYOUT, requiresAuth: false }},
  { path: '/reviewaccess/:code/view/:podid', component: () => import('./accesslink2/AccesslinkView.vue'), meta: { layout: BLANK_LAYOUT, requiresAuth: false }},

  // external view to upload files
  { path: '/uploadaccess/:code', component: () => import('./uploadlink2/UploadlinkIndex.vue'), meta: { layout: LOADING_LAYOUT,   requiresAuth: false}},
  { path: '/uploadaccess/:code/login', component: () => import('./uploadlink2/UploadlinkLogin.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false}},
  { path: '/uploadaccess/:code/view', component: () => import('./uploadlink2/UploadlinkView.vue'), meta: { layout: BLANK_LAYOUT,   requiresAuth: false}},

  // // external view of an embedded pod, the layout will not be updated
  { path: '/embedded/:code', component: () => import('./embedded/EmbeddedIndex.vue'), meta: { layout: BLANK_LAYOUT,   requiresAuth: false}},

  // // status 401 code 1012 your account has expired
  { path: '/expired', component: () => import('./default/ExpiredPage.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: true} },

  // // status 403 response forbidden component
  { path: '/forbidden', component: () => import('./default/ForbiddenPage.vue'), meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

  // // status 404 page not found component
  { path: '*', component: () => import('./default/NotFoundPage.vue'),  meta: { layout: LOGIN_LAYOUT,   requiresAuth: false} },

];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeResolve((to, from, next) => {
  // const VIEWROOTS = router.app.$store.getters.keywords.NODE.VIEWROOTS;
  const REFRESHCOGNITO   = router.app.$store.getters.keywords.AUTH.REFRESHCOGNITO;
  const TENANTISEXPIRED  = router.app.$store.getters.keywords.AUTH.TENANTISEXPIRED;

  // for not authenticated pages
  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  // when the tenant subscription is expired - block the user
  if (router.app.$store.getters[TENANTISEXPIRED]) {
    const allowedUrls = [
      '/account/logout',
      '/console/index',
      '/console/usage',
      '/console/subscription-plans',
    ];

    if (!allowedUrls.includes(to.path)) {
      next({ path: '/console/subscription-plans' });
      return;
    }
  }

  // // for authenticated pages
  // if (
  //   !to.path.startsWith('/pod/') &&
  //   !to.path.startsWith('/node') &&
  //   !to.path.startsWith('/users') &&
  //   !to.path.startsWith('/search') &&
  //   !to.path.startsWith('/notifications')
  // ) {
  //   router.app.$store.dispatch(VIEWROOTS);
  // }

  // reset the pod right menu
  // if (from.path.startsWith('/pod/')) {
  //   const SETPODRIGHTMENU = router.app.$store.getters.keywords.MAIN.SETPODRIGHTMENU;
  //   router.app.$store.commit(SETPODRIGHTMENU, '');
  // }

  router.app.$store.dispatch(REFRESHCOGNITO)
  .then(() => {
    if (to.path.startsWith('/usage')) {
      if (!router.app.$canManageAll()) {
        // router.push('/');
        next({ path: '/' });
        return;
      }
    }

    next();
  }).catch((ew) => {
    console.log("ew", ew);
    next({ path: '/account/login' });
  });
});

router.beforeEach((to, from, next) => {
  if (!history || !history.state || !history.state.key) {
    window.localStorage.browsingHistory = '{}';
  }

  next(); // Allow navigation to proceed
})


router.afterEach((to, from, failure) => {
  if (failure) {
    return;
  }

  // Get the key from the history API to distinguish between the differen URL addresses.
  const key = history?.state?.key;

  if (!key) {
    return;
  }

  let browsingHistory = JSON.parse(window.localStorage.browsingHistory || '{}');

  if (browsingHistory[key]) {
    // Back button is pressed.
    // Get the corresponding root node id from the history and apply it.
    window.localStorage.contextnodeid = browsingHistory[key];

    const SETROOTNODEID = router.app.$store.getters.keywords.AUTH.SETROOTNODEID;
    router.app.$store.dispatch(SETROOTNODEID, window.localStorage.contextnodeid);

  } else {
    // Save the current root node id in the local storage
    // so that we know which rootnodeId is related to the current browser history.
    browsingHistory[key] =  window.localStorage.contextnodeid;
    window.localStorage.browsingHistory = JSON.stringify(browsingHistory);
  }
  to; from; failure;
  // console.log("to, from", to, from, failure);
  // console.log("after each", history.state.key)
})


export default router;
