<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="list-podItem-actions-deletePod-activator">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog vb-dialog-danger">
      <!-- The Regular delete POD card -->
      <v-card v-if="!asset" data-cy="dialog-deletePod-modal">
        <v-card-title class="d-flex justify-space-between" data-cy="dialog-deletePod-title">
          <span>{{$t('routes.node.deletePod')}} {{pod.name}}</span>

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5" data-cy="dialog-deletePod-podName">
          <div v-if="errorMessage" class="error">
            {{ errorMessage }}
          </div>
          <div v-if="errorTranslatable" class="error">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>
          {{$t('routes.node.deletePodDialogMessage')}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation=0 @click="deletePod" class="btnOk" data-cy="dialog-deletePod-ok">{{$t('common.delete')}}</v-btn>
          <v-btn text @click="dialog = false" class="btnCancel" data-cy="dialog-deletePod-false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>

      <!-- The delete ASSET of a pod card -->
      <v-card v-if="asset" data-cy="dialog-deleteAsset-modal">
        <v-card-title class="d-flex justify-space-between" data-cy="dialog-deleteAsset-rawAssetMessage">
          <span>{{$t('routes.pod.deleteAsset')}} {{asset.name}}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5" data-cy="dialog-deleteAsset-assetName">
          <div v-if="errorMessage" class="error">
            {{ errorMessage }}
          </div>
          <div v-if="errorTranslatable" class="error">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>
          <span v-if="isRawAsset">{{$t('routes.node.deletePodRawAssetDialogMessage')}}</span>
          <span v-else>{{$t('routes.node.deletePodAssetDialogMessage')}}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation=0 @click="deletePodAsset" class="btnOk" data-cy="dialog-deleteAsset-ok">{{$t('common.delete')}}</v-btn>
          <v-btn text @click="dialog = false" class="btnCancel" data-cy="dialog-deleteAsset-false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "DeletePodDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: '',
      errorTranslatable: '',
    }
  },
  watch: {
    dialog: function() {
      this.errorMessage = '';
      this.errorTranslatable = '';
    }
  },
  methods: {
    deletePod: function() {
      let self = this;
      const DELETEPODS = this.$store.getters.keywords.POD.DELETEPODS;
      this.$store.dispatch(DELETEPODS, [this.pod.id])
      .then(function() {
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
        }
      });

    },
    deletePodAsset: function() {
      const nodeID = this.pod.nodeid;
      let self = this;

      if (this.isRawAsset) {
        const DELETEPODS = this.$store.getters.keywords.POD.DELETEPODS;
        this.$store.dispatch(DELETEPODS, [this.pod.id])
        .then(function() {
          self.$router.push({path: "/node/" + nodeID});
          self.dialog = false;
        })
        .catch(function(error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            const apiError = error.response.data.error.message;
            if (typeof apiError == 'object') {
              self.errorTranslatable = apiError;
            } else {
              self.errorMessage = apiError;
            }
          } else {
            self.errorMessage = "Error!";
          }
        });
      } else {
        const DELETEPODASSETS = this.$store.getters.keywords.POD.DELETEPODASSETS;
        this.$store.dispatch(DELETEPODASSETS, [ { id: this.asset.id, podid: this.asset.podid }])
        .then(function() {
          self.dialog = false;
        })
        .catch(function(error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            const apiError = error.response.data.error.message;
            if (typeof apiError == 'object') {
              self.errorTranslatable = apiError;
            } else {
              self.errorMessage = apiError;
            }
          } else {
            self.errorMessage = "Error!";
          }
        });
      }
    },
  },
  computed: {
    isRawAsset: function() {
      return  this.asset && this.pod && this.pod.asset &&
              this.pod.asset.raw && this.pod.asset.raw[0] &&
              this.asset.id == this.pod.asset.raw[0].id;
    }
  }
}
</script>

<style>

</style>
