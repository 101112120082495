import API from '../AxiosInstance';

export default {
  getCategories: function(payload) {
    const options = {};

    if (payload.includeLabels === true) {
      options.params = {
        includeLabels: 1,
      };
    }

    return API.get(`tenants/${payload.accessPointId}/labelcategories`, options);
  },

  createCategory: function(payload) {
    const body = {
      name: payload.name,
      parentId: payload.parentId,
    };

    return API.post(`tenants/${payload.accessPointId}/labelcategories`, body);
  },

  updateCategory: function(payload) {
    const body = {
      name: payload.name,
      parentId: payload.parentId,
    };

    return API.patch(`tenants/${payload.accessPointId}/labelcategories/${payload.categoryId}`, body);
  },

  deleteCategory: function(payload) {
    const body = {};
    return API.delete(`tenants/${payload.accessPointId}/labelcategories/${payload.categoryId}`, body);
  },

  getLabels: function(payload) {
    const body = {};

    return API.get(`tenants/${payload.accessPointId}/labelcategories/${payload.categoryId}/labels`, body);
  },

  createLabel: function(payload) {
    const body = {
      label: payload.label,
      categoryId: payload.categoryId,
    };

    return API.post(`tenants/${payload.accessPointId}/labels`, body);
  },

  updateLabel: function(payload) {
    const body = {
      label: payload.label,
      categoryId: payload.categoryId,
    };

    return API.patch(`tenants/${payload.accessPointId}/labels/${payload.labelId}`, body);
  },

  deleteLabel: function(payload) {
    const body = {};
    return API.delete(`tenants/${payload.accessPointId}/labels/${payload.labelId}`, body);
  },

  addMetaLabels: function(payload) {
    const body = {
      podId: payload.podId,
      labels: payload.labels,
      metalabel: true,
    };

    return API.post(`pod/${payload.podId}/labels`, body);
  },
}
