<template>
  <v-dialog
      v-model="displayDialog"
      persistent
      max-height="680"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="confirm-customLabels-activator">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog">
      <v-card v-cloak data-cy="confirm-customLabels-modal">
        <v-card-title class="primary d-flex justify-space-between mb-2">
          {{ $t('routes.pod.menuEditLabels.predefinedLabels') }}

          <v-btn icon @click="displayDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-select
              :items="flatCategories"
              v-model="selectedCategory"
              item-text="name"
              item-value="id"
              :label="$t('routes.pod.menuEditLabels.selectCategory')"
              @change="loadLabels"
              solo flat
          ></v-select>

          <!-- Chips view for labels -->
          <div class="chips-container">
            <v-chip
                v-for="(label, index) in labels"
                :key="index"
                close
                @click:close="removeLabel(index)"
                class="ma-2"
                label
            >
              {{ label.content }}
            </v-chip>
          </div>

          <div v-if="cannotAddLabels" class="font-italic">
            {{ $t('routes.pod.menuEditLabels.noLabels') }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
              :disabled="cannotAddLabels || isProcessingLabels"
              class="ml-auto"
              color="primary"
              elevation=0
              @click="() => this.$emit('selected', this.labels.map((l) => l.content))"
              data-cy="dialog-customLabels-ok"
          >
            {{$t('routes.pod.menuEditLabels.addLabels')}}
          </v-btn>
          <v-btn text @click="displayDialog = false" class="btnCancel" data-cy="dialog-customLabels-false">
            {{$t('common.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "SelectCustomLabelsDialog",
  props: {
    categories: {
      type: Array,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    isProcessingLabels: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['selected'],
  data: function() {
    return {
      labels: [],
      selectedCategory: null,
      displayDialog: false,
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  watch: {
    displayDialog: function (val) {
      if (val) {
        this.selectedCategory = null;
        this.labels = [];
      }
    },
    shouldDisplayDialog: function (val) {
      if (!val) {
        // We only care for this flag, when dialog close is requested. Otherwise,
        // it's up to the activator to show it up.
        this.displayDialog = false;
      }
    }
  },
  methods: {
    loadLabels: function () {
      const payload = {
        categoryId: this.selectedCategory,
        accessPointId: this.accessPointId,
      }

      const FETCHLABELS = this.$store.getters.keywords.LABEL.FETCHLABELS;
      this.$store.dispatch(FETCHLABELS, payload)
        .then((labels) => {
          this.labels = labels;
        })
        .catch((error) => {
          console.error('Error fetching labels:', error);
        });
    },
    removeLabel(index) {
      this.labels.splice(index, 1);
    },
    handleKeyDown(event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.displayDialog = false;
      }
    }
  },
  computed: {
    accessPointId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    flatCategories() {
      const flattenCategories = (categories, level = 0) => {
        return categories.reduce((acc, category) => {
          let name = category.name;

          if (level > 0) {
            name = '└'+'─'.repeat(level * 2) + ' ' + category.name;
          }

          acc.push({
            ...category,
            name,
            level,
          });
          if (category.children) {
            acc = acc.concat(flattenCategories(category.children, level + 1));
          }
          return acc;
        }, []);
      };
      return flattenCategories(this.categories);
    },
    cannotAddLabels() {
      return !this.selectedCategory || this.labels.length === 0;
    },
    shouldDisplayDialog() {
      return this.$props.isVisible;
    },
  },

}
</script>


<style>

</style>
