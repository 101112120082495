<template>
  <v-menu
    v-model="contextMenuModel"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
  >
    <v-list v-if="node" style="overflow-y:auto;" data-cy="nodeSideMenu-treeItemContext-list">

      <DeleteNodeDialog v-if="$canControlNodeAndPod() && !isCurrentNodeTenant" v-bind:node="node">
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-deleteNode">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-trash-can-outline</v-icon>
                {{$t('menus.tools.deleteNode')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </DeleteNodeDialog>

      <ArchiveSelectedDialog
        v-if="node.archived && $canControlNodeAndPod() && $canArchiveNodeAndPod()"
        v-bind:action="'unarchive'"
        v-bind:nodes="Array(node)"
        v-bind:pods="Array()"
      >
        <v-list-item @click="stub" data-cy="nodeSideMenu-treeItemContext-unarchiveSelected">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-archive-arrow-up-outline</v-icon>
                {{$t('menus.tools.unarchiveSelected')}}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </ArchiveSelectedDialog>
    </v-list>
  </v-menu>
</template>


<script>
import DeleteNodeDialog from '../../../../layouts2/mainLayout/components/nodeSidemenu/components/dialogs/DeleteNodeDialog.vue'
import ArchiveSelectedDialog from '../dialogs/ArchiveSelectedDialog.vue'


export default {
  name: "ArchivedNodeContextMenu",
  components: {
    DeleteNodeDialog,
    ArchiveSelectedDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
    node: {
      type: Object,
      // required: true,
    },
  },
  watch: {
    nodeFromState() {
      if (this.nodeFromState && this.nodeFromState.archived !== this.archiveStatus.ARCHIVED) {
        this.contextMenuModel = false;
      }
    },
  },
  methods: {
    stub() {
      // console.log("Click on context menu!");
      this.$emit('input', false);
    },
  },
  computed: {
    contextMenuModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    rootFolderId: function() {
      const ROOTFOLDERID = this.$store.getters.keywords.COMMON.ROOTFOLDERID;
      return this.$store.getters[ROOTFOLDERID];
    },
    nodeTypes: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      return this.$store.getters[NODETYPES];
    },
    archiveStatus() {
      const ARCHIVESTATUS = this.$store.getters.keywords.COMMON.ARCHIVESTATUS;
      return this.$store.getters[ARCHIVESTATUS];
    },
    nodeFromState() {
      if (!this.node || !this.node.id) {
        return null;
      }

      // This is the node from the state manager.
      // The node from the props is from event and does not have watch events.
      const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
      return this.$store.getters[GETNODEBYID](this.node.id);
    },
    isCurrentNodeTenant: function() {
      // return this.node.type === this.nodeTypes.TENANT;
      return this.node.parentid == this.rootFolderId && this.node.type === this.nodeTypes.TENANT;
    },
  },
}
</script>

<style>
</style>
