import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    searchQuery: "",
    searchResults: [],
    searchZone: '',   // It's either 'files' or 'metadata'


    // folderSearchQuery: "",
    folderSearchResults: [],
  },
  getters: {
    getSearchQuery: function(state) {
      return state.searchQuery;
    },
    getSearchZone: function(state) {
      return state.searchZone;
    },
    getSearchResults: function(state) {
      return state.searchResults;
    },
    getAllSearchZones: function() {
      return {
        FILES: "files",
        METADATA: "metadata",
      }
    },

    getFolderSearchResults: function(state) {
      return state.folderSearchResults;
    }
  },
  mutations: {
    setSearchQuery: function(state, query) {
      state.searchQuery = query;
    },
    setSearchZone: function(state, zone) {
      state.searchZone = zone;
    },
    setSearchResults: function(state, results) {
      state.searchResults = results;
    },
    updateSearchResultPod(state, pod) {
      for (let i = 0; i < state.searchResults.length; i++) {
        if (Array.isArray(state.searchResults[i].pods)) {
          for (let j = 0; j < state.searchResults[i].pods.length; j++) {
            if (state.searchResults[i].pods[j].id == pod.id) {
              Object.assign(state.searchResults[i].pods[j], pod);
            }
          }
        }
      }
    },

    setFolderSearchResults(state, results) {
      const updatedData = results.filter(item => item.id !== 648).map(item => {
        if (item.parentid === 648) {
          item.parentid = null;
          item.parent = null;
        }
        return item;
      });

      state.folderSearchResults = updatedData;
    },
  },
  actions: {
    search: function(context, payload) {
      console.log("Search payload:", payload);

      context.commit("setSearchQuery", payload.searchStr);
      context.commit("setSearchZone", payload.searchZone)

      return Api.search.searchNodesAndPods(payload)
      .then(function(result) {
        // console.log("After search result:", result.data);
        context.commit("setSearchQuery", payload.searchStr);
        context.commit("setSearchResults", result.data);
        return result.data;
      })
    },
    updateSearchResultPod(context, pod) {
      context.commit('updateSearchResultPod', pod);
    },

    searchInFolders: function(context, payload) {
      // context.commit("setFolderSearchQuery", payload.searchString);

      return Api.search.searchNodesAndPods(payload)
      .then(function(result) {
        // console.log("After search result:", result.data);
        context.commit('setFolderSearchResults', result.data);
      });
    },
    resetFolderSearchResults: function(context) {
      context.commit('setFolderSearchResults', []);
    }
  },
}
