import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import keywords from './keywords'
import PodStore from './modules/PodStore'
import AuthStore from './modules/AuthStore'
import MainStore from './modules/MainStore'
import NodeStore from './modules/NodeStore'
import UserStore from './modules/UserStore'
import UsageStore from './modules/UsageStore'
import FilterStore from './modules/FilterStore'
import SearchStore from './modules/SearchStore'
import SystemStore from './modules/SystemStore'
import PlayerStore from './modules/PlayerStore'
import UploadStore from './modules/UploadStore'
import CommonStore from './modules/CommonStore'
import ServiceStore from './modules/ServiceStore'
import AccesslinkStore from './modules/AccesslinkStore'
import UploadLinkStore from './modules/UploadLinkStore'
import NotificationStore from './modules/NotificationStore'
import EmbedMediaStore from './modules/EmbedMediaStore'
import AccountStore from './modules/AccountStore'
import SubtitleStore from './modules/SubtitleStore';
import TenantStore from './modules/TenantStore';
import PodLabelStore from './modules/PodLabelStore';
import LabelStore from './modules/LabelStore';

const debug = process.env.NODE_ENV !== 'production'

const modules = {
  main: MainStore,
  auth: AuthStore,
  node: NodeStore,
  user: UserStore,
  pod: PodStore,
  usage: UsageStore,
  filter: FilterStore,
  search: SearchStore,
  system: SystemStore,
  player: PlayerStore,
  upload: UploadStore,
  common: CommonStore,
  service: ServiceStore,
  accesslink: AccesslinkStore,
  uploadlink: UploadLinkStore,
  notification: NotificationStore,
  embedmedia: EmbedMediaStore,
  account: AccountStore,
  subtitle: SubtitleStore,
  tenant: TenantStore,
  podlabel: PodLabelStore,
  label: LabelStore,
  // ... //
};

var cleanStates = {};
const moduleKeys = Object.keys(modules);


for (let j = 0; j < moduleKeys.length; j++) {
  // deep copy the original state
  cleanStates[moduleKeys[j]] = JSON.parse(JSON.stringify(modules[moduleKeys[j]].state));

  // Assign universal reset function to each module
  modules[moduleKeys[j]].mutations.resetFunction = resetFunction;
}


// The universal reset function
function resetFunction(state, originalState) {
  const keys =  Object.keys(originalState);
  for (let i = 0; i < keys.length; i++) {
    // deep copy the new state
    let newValue = JSON.parse(JSON.stringify(originalState[keys[i]]));
    state[keys[i]] = newValue;
  }
}


let store = new Vuex.Store({
  modules: modules,
  getters: {
    keywords: function() {
      return keywords;
    }
  },
  mutations: {
    resetAll: function() {
      for (let i = 0; i < moduleKeys.length; i++) {
        // For each module commit the reset function
        const resetKeyword = moduleKeys[i] + "/resetFunction";
        this.commit(resetKeyword, cleanStates[moduleKeys[i]]);
      }
    },
  },
  strict: debug
});

export default store;
