<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary d-flex justify-space-between mb-4">
        <span>{{$t('routes.pod.menuAccesslink.shareFilesLink')}}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable">
        <v-form ref="form" :disabled="isFormDisabled">
          <v-row>
            <v-col cols=8>
              <div class="d-flex">
                <v-text-field
                  type="text"
                  v-bind:label="$t('routes.pod.menuAccesslink.password')"
                  v-model="password"
                  v-bind:rules="passwordRules"
                  required block solo flat
                  data-cy="podMenu-tools-manageGenericLinks-password">

                  <template slot="append">
                    <v-tooltip bottom z-index="1200">
                      <template v-slot:activator="{on, attrs}">
                        <v-btn tile icon v-bind="attrs" v-on="on" v-on:click="generatePassword">
                          <v-icon>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>

                      <span>{{$t('routes.users.generatePassword')}}</span>
                    </v-tooltip>
                  </template>

                </v-text-field>
              </div>

              <div>
                <v-menu
                  v-model="datepickerMenu"
                  v-bind:close-on-content-click="false"
                  v-bind:nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expiryDate"
                      v-bind:label="$t('routes.pod.menuAccesslink.expiryDate')"
                      v-bind:rules="expiryDateRules"
                      append-icon="mdi-calendar"
                      readonly required block
                      solo flat
                      v-bind="attrs"
                      v-on="on"
                      data-cy="podMenu-tools-manageGenericLinks-dateMenu"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="expiryDate"
                    v-on:input="datepickerMenu = false"
                    v-bind:min="getMinDatepicker"
                    data-cy="podMenu-tools-manageGenericLinks-datePicker"
                  ></v-date-picker>

                </v-menu>
              </div>
            </v-col>

            <v-col cols=4>
              <div v-if="isTeamMemberUser" data-cy="podMenu-tools-manageGenericLinks-teamOnly">
                <v-checkbox
                    class="mt-0"
                    v-model="teamOnly"
                    v-bind:disabled="isTeamonlyDisabled || isFormDisabled"
                    v-bind:label="$t('routes.pod.menuAccesslink.teamOnly')"
                    hide-details required
                >
                </v-checkbox>

                <div v-if="isTeamonlyDisabled">
                  {{$t('routes.pod.menuAccesslink.teamonlyMustBeChecked')}}
                </div>
              </div>

              <div data-cy="podMenu-tools-manageGenericLinks-allowDownloading">
                <v-checkbox
                    v-model="allowDownloading"
                    v-bind:label="$t('routes.pod.menuAccesslink.allowDownloading')"
                    hide-details required block
                >
                </v-checkbox>
              </div>

              <div data-cy="podMenu-tools-manageGenericLinks-allowComments">
                <v-checkbox
                    v-model="allowComments"
                    v-bind:disabled="isCommentsDisabled || isFormDisabled"
                    v-bind:label="$t('routes.pod.menuAccesslink.allowComments')"
                    hide-details required block
                >
                </v-checkbox>

                <div v-if="isCommentsDisabled">
                  {{$t('routes.pod.menuAccesslink.commentsAreNotAllowed')}}
                </div>
              </div>

              <div class="pb-4" data-cy="podMenu-tools-manageGenericLinks-showAssets">
                <v-checkbox
                    v-model="showAssets"
                    v-bind:label="$t('routes.pod.menuAccesslink.showAssets')"
                    hide-details required block
                >
                </v-checkbox>
              </div>
            </v-col>
          </v-row>

          <div data-cy="podMenu-tools-manageGenericLinks-notes">
            <!-- <v-textarea
              v-model="linkNotes"
              v-bind:label="$t('routes.pod.menuAccesslink.notes')"
              outlined
              dense
              rows="3"
              data-cy="podMenu-tools-manageGenericLinks-notes"
            ></v-textarea> -->

            <VueSimplemde v-model="linkNotes" ref="markdownEditor" preview-class="markdown-preview" v-bind:configs="simplemdeConfigs">
            </VueSimplemde>
          </div>

          <div>
            <v-checkbox
                v-model="notifyViaEmail"
                v-bind:label="$t('routes.pod.menuAccesslink.notifyViaEmail')"
                hide-details required
            >
            </v-checkbox>
          </div>

          <div v-if="notifyViaEmail">
            <v-form ref="emailsForm" class="mt-4" @submit.prevent="addEmailRecipient">
              <v-text-field
                v-model="addEmailInput"
                v-bind:rules="addEmailInputRules"
                v-bind:label="$t('routes.pod.menuAccesslink.enterEmailAddressAndPress')"
                solo flat
              >
              <template slot="append">
                <v-btn tile icon v-show="addEmailInput" @click="addEmailRecipient">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              </v-text-field>
            </v-form>

            <div v-if="emailRecipientsList.length > 0" class="mb-4 vb-invited-recipients">
              <v-chip
                v-for="email in emailRecipientsList"
                :key="email"
                @click:close="removeEmailRecipient(email)"
                color="primary"
                close
                close-icon="mdi-close"
              >
                {{email}}
              </v-chip>
            </div>
          </div>

          <div v-if="notifyViaEmail">
            <v-textarea
              v-model="emailNote"
              v-bind:label="$t('routes.pod.menuAccesslink.addNoteToEmail')"
              solo flat
              rows="3"
            ></v-textarea>
          </div>

          <div v-show="successMessage" class="success mt-2" data-cy="podMenu-tools-">{{successMessage}}</div>
          <div v-show="errorMessage" class="error mt-2" data-cy="podMenu-tools-">{{errorMessage}}</div>
        </v-form>

        <div v-if="updatedLink">
          <div class="d-flex mt-4">
            <div class="flex-grow-1">
              URL:

              <v-text-field
                readonly
                solo flat
                :value="getAccesslinkURL"
                hide-details="auto"
              >
              </v-text-field>
            </div>
            <div class="flex-grow-0 pt-6 pl-2">
              <v-tooltip bottom z-index="1200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs" v-on="on"
                      type="button"
                      v-on:click="copyToClipboard()"
                      tile icon
                      data-cy="podMenu-tools-embedMediaPreview-copyUrl-btn"
                  >
                    <v-icon tile>mdi-clipboard-file-outline</v-icon>
                  </v-btn>
                </template>

                {{$t('routes.pod.menuEmbedMedia.copyToClipboard')}}
              </v-tooltip>
            </div>
          </div>

          <div v-if="updatedLink?.invitations && Array.isArray(updatedLink.invitations) && updatedLink.invitations.length > 0" class="mt-4">
            <span class="font-weight-bold">{{ $t('routes.pod.menuAccesslink.invitedPeople') }}:</span> {{ updatedLink.invitations.map((i) => i.email).join(', ') }}
          </div>
        </div>

        <v-row class="mt-4">
          <v-col v-if="pods.length > 0">
            <h4>{{ $t('routes.pod.menuAccesslink.selectedFiles') }}: </h4>
            <v-list dense>
              <v-list-item v-for="pod in pods" :key="pod.id">
                <v-list-item-content>
                  <div>
                    <span v-if="pod.nodeid && getNodeById(pod.nodeid)">
                      {{ getNodeById(pod.nodeid).name }} /
                    </span>
                    {{ pod.name }}
                  </div>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-btn icon small @click="removePod(pod)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col v-else class="text-center error">
            {{$t('routes.pod.noSelectedFile')}}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <SelectPodsDialog @selectedPods="addPodByIds">
              <v-btn color="primary" elevation="0">
                {{$t('routes.node.addFiles')}}
              </v-btn>
            </SelectPodsDialog>
          </v-col>
        </v-row>
      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="shareFiles" :disabled="isFormDisabled" color="primary" elevation=0 data-cy="dialog--ok">{{$t('common.create')}}</v-btn>
        <v-btn @click="dialog = false" text data-cy="dialog--false">
          {{ updatedLink ? $t('common.close') : $t('common.cancel') }}
        </v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import moment from 'moment';
import VueSimplemde from 'vue-simplemde'
import SelectPodsDialog from '../../../pods2/components/dialogs/SelectPodsDialog.vue';

import MimetypeHelper from '../../../../components/MimetypeHelper.js'
import Helper from '@/components/helper';
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "AccesslinkCreateDialog",
  components: {
    VueSimplemde,
    SelectPodsDialog,
  },
  props: {
    rootNode: {
      type: Object,
      required: true,
    },
    selectedPods: {
      type: Array,
      required: true,
    },
    setAllowComments: {
      type: Boolean,
      required: false,
    },
    setNotifyViaEmail: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return {
      dialog: false,

      pods: [],

      password: "",
      passwordRules: [
        function(v) {
          const value = v ? v.trim() : v;

          if (this.allowComments && !value) {
            return this.$t('routes.pod.menuAccesslink.passwordIsRequiredWith')
          }

          if (value && value.length < 8) {
            return this.$t('menus.createUser.passwordLength');
          }

          if (value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*&^%$#@!]).*$/.test(value) === false) {
            return this.$t('menus.createUser.passwordInvalidChars');
          }

          return true;
        }.bind(this),
      ],

      datepickerMenu: false,
      expiryDate: '',
      expiryDateRules: [
        v => !!v || this.$t('routes.pod.menuAccesslink.expiryDateRequired')
      ],

      showAssets: false,
      allowDownloading: true,
      allowComments: false,
      notifyViaEmail: false,
      teamOnly: false,

      linkNotes: "",

      addEmailInput: "",
      addEmailInputRules: [
        function(v) {
          if (v) {
            return emailRegex.test(v) || "Invalid email address.";
          }
          return true;
        }
      ],

      emailRecipientsList: [],
      emailNote: '',

      errorMessage: "",
      successMessage: "",

      updatedLink: null,

      simplemdeConfigs: {
        spellChecker: false,
        placeholder: this.$t('routes.pod.menuAccesslink.notes'),
        toolbar: [
          'bold', 'italic', 'strikethrough', 'heading', '|',
          'code', 'unordered-list', 'ordered-list', '|',
          'link', 'image', 'table', '|',
          'preview', 'guide'
        ],
        status: false,
      }
    };
  },
  mounted() {
    // this.expiryDate = moment.utc(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format('YYYY-MM-DD');
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.resetForm();
      }
    },
    isTeamonlyDisabled(newValue) {
      if (newValue) {
        this.teamOnly = true;
      }
    },
  },
  methods: {
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.resetFormData();
      });
    },
    resetFormData() {
      // copy selected pods to pods
      this.pods = (this.selectedPods || []).map(x => x);

      this.password = '';
      this.datepickerMenu = false;
      this.expiryDate = moment.utc().add(1, 'year').format('YYYY-MM-DD');

      this.showAssets = false;
      this.allowDownloading = true;
      this.allowComments = false;
      this.notifyViaEmail = false;
      this.teamOnly = this.isTeamonlyDisabled ? true : false;

      this.linkNotes = "";
      this.addEmailInput = "";

      this.emailRecipientsList = [];
      this.emailNote = '';

      this.errorMessage = "";
      this.successMessage = "";

      this.updatedLink = null;

      if (this.setAllowComments) {
        this.allowComments = true;
      }

      if (this.setNotifyViaEmail) {
        this.notifyViaEmail = true;
      }
    },
    shareFiles: function() {
      this.errorMessage = "";
      this.successMessage = "";

      const validForm = this.$refs.form.validate();
      if (!validForm || !this.pods || this.pods.length == 0) {
        return;
      }

      let formData = {
        podids: this.pods.map(x => x.id),
        password: this.password,
        note: this.linkNotes,
        showassets: this.showAssets ? 1 : 0,
        allowdownloading: this.allowDownloading ? 1 : 0,
        allowcomments: this.allowComments ? 1 : 0,
        teamonly: this.teamOnly ? 1 : 0,
        // Send the date in UTC
        expireat: moment(this.expiryDate + " 23:59:59").utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      };

      if (this.isCommentsDisabled) {
        formData.allowComments = 0;
      }

      const CREATEACCESSLINK = this.$store.getters.keywords.ACCESSLINK.CREATEACCESSLINK;
      const UPDATEACCESSLINK = this.$store.getters.keywords.ACCESSLINK.UPDATEACCESSLINK;
      const REFRESHACCESSLINKS = this.$store.getters.keywords.ACCESSLINK.REFRESHACCESSLINKS;
      let VERB = CREATEACCESSLINK;
      let self = this;

      if (this.accesslink) {
        formData.id = this.accesslink.id;
        VERB = UPDATEACCESSLINK;
      }

      this.$store.dispatch(VERB, formData)
      .then(function(accesslink) {
        if (self.notifyViaEmail && self.emailRecipientsList.length > 0) {
          // return self.sendInvitations(accesslink).then((links) => links.find(x => x.id === accesslink.id));
          return self.sendInvitations(accesslink).then((links) =>{
            if (links.length > 0) {
              return links.find(x => x.id === accesslink.id);
            }

            return accesslink;
          });
        } else {
          return accesslink;
        }
      })
      .then(function(accessLink) {
        self.updatedLink = accessLink;
        self.$store.dispatch(REFRESHACCESSLINKS, self.rootNode.id);
      })
      .catch(function(error){
        if (error?.response?.data?.error) {
          self.errorMessage = self.$localizeMessage(error.response.data.error);
        } else {
          self.errorMessage = self.$t('backend.system-error');
          console.log(error?.response?.data); // unhandled error
        }
      });
    },
    addPodByIds(podIds) {
      for (let i = 0; i < podIds.length; i++) {
        const isAdded = this.pods.find(x => x.id === podIds[i]);

        if (!isAdded) {
          const pod = this.getPodById(podIds[i]);
          this.pods.push(pod);
        }
      }
    },
    removePod: function(pod) {
      let i = this.pods.indexOf(pod);
      this.pods.splice(i, 1);
    },
    generatePassword: function() {
      const passwordLength = 10;
      const randPassword = Helper.generatePassword(passwordLength);

      this.password = randPassword;
    },
    addEmailRecipient() {
      const validForm = this.$refs.emailsForm.validate();

      if (!validForm || !this.addEmailInput) {
        return;
      }

      if (this.emailRecipientsList.indexOf(this.addEmailInput) >= 0) {
        this.addEmailInput = "";
        return;
      }

      this.emailRecipientsList.push(this.addEmailInput);
      this.addEmailInput = "";
    },
    removeEmailRecipient(email) {
      this.emailRecipientsList = this.emailRecipientsList.filter(x => x !== email);
    },
    async sendInvitations(accesslink) {
      const payload = {
        linkId: accesslink.id,
        receiver: this.emailRecipientsList.join(','),
        note: this.emailNote,
      };

      const self = this;

      const INVITEACCESSLINK = this.$store.getters.keywords.ACCESSLINK.INVITEACCESSLINK;
      return this.$store.dispatch(INVITEACCESSLINK, payload)
        .then(function(response) {
          self.emailRecipientsList = [];
          self.emailNote = '';

          return Array.isArray(response?.links) ? response.links : [];
        });
    },
    copyToClipboard: function() {
      // Note: this works only on localhost and HTTPS
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(this.getAccesslinkURL)
      }
    },
  },
  computed: {
    getMinDatepicker: function() {
      return moment().format('YYYY-MM-DD');
    },
    getPodById: function() {
      const GETPODBYID = this.$store.getters.keywords.POD.GETPODBYID;
      return function(podId) {
        return this.$store.getters[GETPODBYID](podId);
      }
    },
    isFormDisabled() {
      return !this.pods || this.pods.length == 0 || Boolean(this.updatedLink);
    },
    getAccesslinkURL() {
      if (this.updatedLink && this.updatedLink.url) {
        return window.location.origin + this.updatedLink.url;
      }
      return '';
    },
    isTeamonlyDisabled: function() {
      return this.pods.some(x => x.teamonly);
    },
    isCommentsDisabled: function() {
      // comments are allowed only for video and audio pods
      const hasVideoPod = this.pods.find(x => MimetypeHelper.isMimetypeVideo(x));
      const hasAudioPod = this.pods.find(x => MimetypeHelper.isMimetypeAudio(x));

      return !(hasVideoPod || hasAudioPod);
    },
    isTeamMemberUser: function() {
      return this.$canControlTenantConfig() || this.$canBelongstoTeam();
    },
    getNodeById: function() {
      const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
      const GETFULLNODEINFO = this.$store.getters.keywords.NODE.GETFULLNODEINFO;

      return function(nodeId) {
        // const nodeId = item.pod.nodeid;
        const node = this.$store.getters[GETNODEBYID](nodeId);
        if (!node) {
          this.$store.dispatch(GETFULLNODEINFO, nodeId);
          return null;
        }

        return node;
      }
    },
  }
}
</script>

<style>
.vb-invited-recipients .v-chip {
  color: #1c1c1c !important;
  margin-top: 0.5rem;
}
</style>
