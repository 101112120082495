<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary">
        {{$t('menus.uploadFiles.choosePreset')}}
      </v-card-title>

      <v-card-text class="pt-4 pb-0">
        {{$t('menus.uploadFiles.useDefaultPreset')}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="choosePreset">{{$t('menus.uploadFiles.presetYes')}}</v-btn>
        <v-btn text @click="useDefaultPreset">{{$t('menus.uploadFiles.presetNo')}}</v-btn>
        <v-btn text @click="cancel">{{$t('menus.uploadFiles.presetCancel')}}</v-btn>
        <input ref='inputref' type='file' accept = '.epr' v-on:change="startUpload" style='display:none;'>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { getCredentialsForIdentity } from '@aws-amplify/core';
import { S3Client, CreateMultipartUploadCommand, UploadPartCommand, CompleteMultipartUploadCommand } from '@aws-sdk/client-s3';
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "APPresetDialog",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      dialog: false,
    }
  },
  mounted: function() {
  },
  methods: {
    choosePreset: function() {
      this.dialog = false;
      this.$refs.inputref.value = null;
      this.$refs.inputref.click();
    },
    startUpload: function(event) {
        var file = event.target.files[0];
        let presetPath = file.path;
        // eslint-disable-next-line no-undef
        var csInterface = new CSInterface;
        var OSVersion = csInterface.getOSInformation();
        if (OSVersion && OSVersion.indexOf("Windows") >= 0) {
          const sep = '\\\\';
          presetPath = presetPath.replace(/\\/g, sep);
        }
        this.uploadSequence(presetPath);
    },
    useDefaultPreset: function() {

      this.dialog = false;
      // eslint-disable-next-line no-undef
      var csInterface = new CSInterface;

      var OSVersion = csInterface.getOSInformation();
      // eslint-disable-next-line no-undef
      var path = csInterface.getSystemPath(SystemPath.EXTENSION);
      var thePath = null;

      if (OSVersion) {
        // The path always comes back with '/' path separators. Windows needs '\\'.
        var sep = '/';
        if (OSVersion.indexOf("Windows") >= 0) {
          sep = '\\\\';
          path = path.replace(/\//g, sep);
        } else {
          sep = '/';
        }

        path = path + sep + 'example.epr';

        thePath = path;
      }

      this.uploadSequence(thePath);

    },
    cancel: function() {
      this.dialog = false;
    },
    uploadSequence: function(presetPath) {

      // eslint-disable-next-line no-undef
      var csInterface = new CSInterface;

      const script = `getActiveSequence("${presetPath}")`;

      let self = this;

      csInterface.evalScript(script, (result) => {
        if (result) {
          let filePath = result;
          console.log(filePath);
          // eslint-disable-next-line no-undef
          const fs = cep_node.require('fs');
          let fileUuid = uuidv4();
          // eslint-disable-next-line no-undef
          const path = cep_node.require('path');
          const name = path.basename(filePath);

          let useruuid = self.data.useruuid;
          let nodeid = self.data.nodeid;

          fs.stat(filePath, async (error, stats) => {

            let size = stats.size.toString()

            let podData = {
              uuid: fileUuid,
              filename: name,
              filesize: size,
              useruuid: useruuid,
              nodeid: nodeid,
              note: "",
            };
            let VERB = this.$store.getters.keywords.POD.CREATEPOD;
            try {
              await this.$store.dispatch(VERB, podData, { root: true });

              // const cred = await Credentials.get();
              const cred = await getCredentialsForIdentity();

              const readable = fs.createReadStream(filePath, {
                highWaterMark: 50 * 1024 * 1024,
              });

              let client = new S3Client({
                region: window.awsconfig.aws_cognito_region,
                credentials: cred,
              });
              const createParams = {
                Bucket: window.awsconfig.aws_user_files_s3_bucket,
                Key: "public/" + fileUuid,
                Metadata: {
                      fileUuid: fileUuid,
                      useruuid: useruuid,
                      nodeid: nodeid,
                      filesize: size,
                      filename: name,
                },
              };
              const command = new CreateMultipartUploadCommand(createParams);
              const response = await client.send(command);
              const uploadID = response.UploadId;

              var partNumber = 1;
              var completeParams = {
                Bucket: createParams.Bucket,
                Key: createParams.Key,
                UploadId: uploadID,
                MultipartUpload: {
                  Parts: [],
                },
              }
              for await (const chunk of readable) {
                const uploadParams = {
                  Body: chunk,
                  Bucket: createParams.Bucket,
                  Key: createParams.Key,
                  UploadId: uploadID,
                  PartNumber: partNumber,
                };
                const data = await client.send(new UploadPartCommand(uploadParams));
                completeParams.MultipartUpload.Parts.push({ETag: data.ETag, PartNumber: partNumber});
                partNumber += 1;
              }

              const finalData = await client.send(new CompleteMultipartUploadCommand(completeParams));
              console.log(finalData);
            } catch (err) {
              console.log("Error uploading file: ", err);
            }
          });
        } else {
          console.log("The operation could not be completed.");
        }
      });
    },
  }
}
</script>

<style>

</style>
