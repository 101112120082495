<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podFiles-tree-actions-deleteAsset-activator">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog vb-dialog-danger">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{$t('routes.pod.menuUploadAsset.deleteAssetDialogTitle')}}</span>

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="form" v-on:submit.prevent="deleteAssets()" data-cy="dialog-deleteAsset-modal">
          <v-card-text class="pb-0">
            <div v-if="hasSelectedRawAsset" class="mb-2">
              <div class="mb-2">{{$t('routes.pod.selectedContainsOriginAsset')}}</div>
              <div>{{$t('routes.pod.deleteOriginAssetDialogMessage')}}</div>
            </div>

            <div>{{$t('routes.pod.deleteAssetConfirmation')}}</div>

            <div v-if="errorMessage" class="error" data-cy="dialog-deleteAsset-error">
              {{errorMessage}}
            </div>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn type="submit"
                   elevation=0
                   data-cy="dialog-deleteAssets-ok"
                   class="btnOk"
                   :disable="preventMultiClick"
            >{{$t('common.delete')}}</v-btn>
            <v-btn text @click="dialog = false" class="btnCancel">{{$t('common.cancel')}}</v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "DeleteSelectedAssetsDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      dialog: false,
      preventMultiClick: false,
      errorMessage: "",
    };
  },
  watch: {
    dialog: function(newValue) {
      if (newValue) {
        this.errorMessage = "";
      }
    }
  },
  methods: {
    deleteAssets: function() {
      this.errorMessage = "";
      this.preventMultiClick = true;

      if (!this.hasSelectedAssets) {
        this.errorMessage = this.$t('routes.pod.deleteSelectedAssetsError');
        return;
      }

      let data;
      let action;

      if (this.hasSelectedRawAsset) {
        action = this.$store.getters.keywords.POD.DELETEPODS;
        data = [this.pod.id];
      } else {
        action = this.$store.getters.keywords.POD.DELETEPODASSETS;
        data = this.selectedAssets.map((x) => ({ id: x.id, podid: x.podid }));
      }

      const countOfSelectedAssets = this.selectedAssets.length;

      let self = this;

      this.$store.dispatch(action, data)
        .then(function() {
          const localizedMessage = self.$tc('routes.pod.menuUploadAsset.assetsDeletedSuccessfully', countOfSelectedAssets);
          self.$showMessage(localizedMessage);

          if (self.hasSelectedRawAsset) {
            self.$router.push(`/node/${self.pod.nodeid}`);
          }
        })
        .catch(function(error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            self.errorMessage = error.response.data.error.message;
          } else {
            self.errorMessage = "Error!";
            console.log(error);   // unhandled error
          }

          self.preventMultiClick = false;
        });

      this.dialog = false;
      this.$emit('assetsDeleted');
    },
  },
  computed: {
    assetTypes: function() {
      const ASSETTYPES = this.$store.getters.keywords.COMMON.ASSETTYPES;
      return this.$store.getters[ASSETTYPES];
    },
    selectedAssets: function() {
      const GETSELECTEDASSETS = this.$store.getters.keywords.POD.GETSELECTEDASSETS;
      return this.$store.getters[GETSELECTEDASSETS];
    },
    hasSelectedAssets: function() {
      return this.selectedAssets.length > 0;
    },
    hasSelectedRawAsset: function() {
      const rawAssetFound = this.selectedAssets.find((x) => x.type === this.assetTypes.RAW);
      return rawAssetFound !== undefined;
    }
  }
}
</script>

<style>

</style>
