import API from '../AxiosInstance';

export default {
  getAllTenants: function() {
    const options = {};
    return API.get('tenants', options);
  },
  deleteTenant: function(id) {
    const options = {};
    return API.delete('tenants/' + id, options);
  },
  restoreTenant: function(payload) {
    const body = {};
    return API.patch(`tenants/${payload.nodeId}/restore`, body);
  },

}
