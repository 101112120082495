<template>
  <div>
    <div v-if="!pod">
      File not found!
    </div>

    <div v-if="pod" class="vb-subtitles">

      <div class="d-flex align-center">
        <div class="flex-grow-1" data-cy="podFiles-subtitles-title">
          <h1>{{$t('routes.pod.menuSubtitles.subtitles')}}</h1>
        </div>

        <div v-if="$canRunPaidService() && (isVideoPod || isAudioPod)">
          <v-menu offset-y v-model="assetMenuIsActive">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" data-cy="podFiles-subtitles-newSubtitleMenu-btn">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <CreateSubtitlesDialog @subtitleCreated="closeMenu">
                <v-list-item data-cy="podFiles-subtitles-newSubtitle-btn">
                  <v-list-item-icon>
                    <v-icon small>mdi-plus-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('routes.pod.menuSubtitles.createSubtitle') }}</v-list-item-title>
                </v-list-item>
              </CreateSubtitlesDialog>

              <UploadSubtitlesDialog :pod="pod" @subtitleCreated="closeMenu">
                <v-list-item data-cy="podFiles-subtitles-uploadSubtitle-btn">
                  <v-list-item-icon>
                    <v-icon small>mdi-file-upload</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('routes.pod.menuSubtitles.addSubtitle') }}</v-list-item-title>
                </v-list-item>
              </UploadSubtitlesDialog>

            </v-list>
          </v-menu>
        </div>
      </div>

      <v-container fluid>
        <PodSubtitleAssetsList
          v-model="subtitleAssetId"
          v-bind:assets="subtitleAssets"
          v-on:setAssetForEditing="setAssetForEditing"
          data-cy="podMenu-subtitles-list"
        >
        </PodSubtitleAssetsList>
      </v-container>

      <template v-if="selectedAsset">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-btn color="primary"
                     v-bind:disabled="this.isFormShown"
                     elevation=0
                     @click="addNewLine"
                     dense>
                <v-icon>mdi-file-edit</v-icon>
                {{ $t('routes.pod.menuSubtitles.newLine') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                v-bind:label="$t('common.search')"
                solo flat
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    elevation=0
                  >
                    <v-icon>mdi-filter-cog-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="stub">
                    <v-list-item-title>
                      <v-switch
                        v-model="subtitlesInVideo"
                        v-bind:label="subtitlesInVideo ? $t('routes.pod.menuSubtitles.disableSubInVideo') : $t('routes.pod.menuSubtitles.enableSubInVideo')"
                        @change="toggleSubtitlesInVideo"
                      ></v-switch>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <!-- the rest of your grid layout -->
        </v-container>

        <v-container fluid class="subtitle-lines">
          <v-row class="font-weight-bold">
            <v-col cols="2">{{ $t('routes.pod.menuSubtitles.time') }}</v-col>
            <v-col cols="2">{{ $t('routes.pod.menuSubtitles.durationShort') }}</v-col>
            <v-col cols="8">{{ $t('routes.pod.menuSubtitles.lines') }}</v-col>
          </v-row>
          <v-row v-if="showCreationForm">
            <v-col cols="12">
              <PodSubtitlesForm
                  v-bind:subtitleForEditing="subtitleForEditing"
                  v-bind:pod="pod"
                  v-on:visibility="showCreationForm = $event"
                  data-cy="podMenu-subtitles-subtitlesForm">
              </PodSubtitlesForm>
            </v-col>
          </v-row>
          <v-row v-for="subtitle in filteredSubtitles"
             v-bind:key="subtitle.id"
             v-on:dblclick="setSubtitleForEditing(subtitle.id)"
             v-on:contextmenu.prevent="showContextMenu($event, subtitle)"
             ref="subtitleLineRefs"
             class="subtitle-line"
          >

            <v-col cols="12">
              <v-row v-bind:class="isSelectedLine(subtitle)" >
                <v-col cols="2">{{ convertToTimeFormat(subtitle.startTime) }}</v-col>
                <v-col cols="2">{{ $tc('routes.pod.menuSubtitles.nSecondsShort', duration(subtitle)) }} </v-col>
                <v-col cols="8" style="white-space: pre-wrap;">
                  {{ subtitle.text }}
                </v-col>
              </v-row>

              <v-row v-if="canEditSubscriptionLine(subtitle)">
                <v-col cols="12">
                  <PodSubtitlesForm
                      v-bind:subtitleForEditing="subtitleForEditing"
                      v-bind:pod="pod"
                      v-on:visibility="showForm = $event"
                      data-cy="podMenu-subtitles-subtitlesForm">
                  </PodSubtitlesForm>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Context Menu -->
          <v-menu v-model="contextMenu.show" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute>
            <v-list>
              <v-list-item @click="editSubtitleLine()">
                <v-list-item-title>
                  <v-icon>mdi-pencil</v-icon>
                  {{ $t('common.edit') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteSubtitleLine()">
                <v-list-item-title>
                  <v-icon>mdi-delete-outline</v-icon>
                  {{ $t('common.delete') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import PodSubtitleAssetsList from './PodSubtitleAssetsList.vue';
import PodSubtitlesForm from './PodSubtitlesForm.vue';
import CreateSubtitlesDialog from '@/routes/pods2/components/dialogs/CreateSubtitlesDialog.vue';
import UploadSubtitlesDialog from '@/layouts2/mainLayout/components/podSidemenu/subtitlesToolbar/dialogs/UploadSubtitlesDialog.vue';

export default {
  name: "SubtitlesToolbar",
  components: {
    UploadSubtitlesDialog,
    CreateSubtitlesDialog,
    PodSubtitleAssetsList,
    PodSubtitlesForm
  },
  data() {
    return {
      search: null,
      subtitleAssetId: null,
      subtitleForEditing: null,
      showForm: false,
      showCreationForm: false,
      subtitlesInVideo: false,
      selectedSubtitleLine: null,

      headers: [
        { text: 'Time', value: 'startTime' },
        { text: 'Duration', value: 'duration' },
        { text: 'Line', value: 'text' },
      ],

      contextMenu: {
        show: false,
        x: 0,
        y: 0
      },
      assetMenuIsActive: false,
    };
  },
  mounted() {
    if (!this.pod) {
      return;
    }

    const RESETSTATE = this.$store.getters.keywords.SUBTITLE.RESETSTATE;
    this.$store.dispatch(RESETSTATE);
  },
  watch: {
    showForm: function (value) {
      if (!value) {
        this.subtitleForEditing = null;
        this.selectedSubtitleLine = null;
      }
    },
    showCreationForm: function (value) {
      if (!value) {
        this.subtitleForEditing = null;
      }
    },
    playerCurrentTime: function(/* time */) {
      // console.log("Time updated", time);
      if (this.subtitlesInVideo) {
        this.scrollToActiveLine();
      }
    },
  },
  methods: {
    stub: function (value) {
      console.log("Stub", value);
    },
    closeMenu() {
      this.assetMenuIsActive = false;
    },
    setAssetForEditing: function(assetId) {
      this.subtitleAssetId = assetId;
      this.reset();

      const SELECTSUBTITLEASSET = this.$store.getters.keywords.SUBTITLE.SELECTSUBTITLEASSET;
      this.$store.dispatch(SELECTSUBTITLEASSET, { assetId });

      if (this.subtitlesInVideo) {
        this.setPlayerAsset();
      }
    },
    setSubtitleForEditing: function(subtitleId) {
      this.showForm = true;
      this.showCreationForm = false;

      const foundFilteredSubtitle = this.filteredSubtitles.find((subtitle) => subtitle.id === subtitleId);
      this.subtitleForEditing = foundFilteredSubtitle;

      if (this.subtitlesInVideo) {
        const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
        this.$store.commit(SETSEEKTIMEEVENT, foundFilteredSubtitle.startTime / 1000);
      }
    },
    convertToTimeFormat(milliseconds) {
      let seconds = Math.floor((milliseconds / 1000) % 60),
          minutes = Math.floor((milliseconds / (1000 * 60)) % 60),
          hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    addNewLine: function () {
      this.subtitleForEditing = {
        startTime: this.playerCurrentTime * 1000,
        endTime: (this.playerCurrentTime * 1000) + 2000,
        duration: 2,
        text: '',
      };

      this.showForm = false;
      this.showCreationForm = true;
    },
    showContextMenu(event, subtitle) {
      this.contextMenu.show = true;
      this.contextMenu.x = event.clientX;
      this.contextMenu.y = event.clientY;

      this.selectedSubtitleLine = subtitle;
    },
    editSubtitleLine() {
      this.contextMenu.show = false; // close the context menu
      this.subtitleForEditing = this.selectedSubtitleLine;
      this.showForm = true;
    },
    deleteSubtitleLine() {
      this.contextMenu.show = false; // close the context menu

      const payload = {
        subtitle: {
          id: this.selectedSubtitleLine.id,
        },
      };

      const REMOVESUBTITLELINE = this.$store.getters.keywords.SUBTITLE.REMOVESUBTITLELINE;
      this.$store.commit(REMOVESUBTITLELINE, payload);

      this.saveAsset();
    },
    saveAsset: function() {
      let self = this;

      const GETTRANSFORMEDSUBTITLES = this.$store.getters.keywords.SUBTITLE.GETTRANSFORMEDSUBTITLES;
      const cues = this.$store.getters[GETTRANSFORMEDSUBTITLES];

      const data = {
        podID: this.pod.id,
        assetID: this.selectedAsset.id,
        payload: {
          cues,
        }
      };

      const UPDATESUBTITLEASSET = this.$store.getters.keywords.POD.UPDATESUBTITLEASSET;
      this.$store.dispatch(UPDATESUBTITLEASSET, data)
        .catch(function(error) {
          if (error && error.message) {
            self.$showMessage(error.message, { type: 'error' });
          } else {
            console.log(error);
            self.$showMessage(self.$t('backend.system-error'), { type: 'error' });
          }
        });
    },
    reset: function() {
      this.showForm = false;
      this.showCreationForm = false;
      this.subtitleForEditing = null;
      this.selectedSubtitleLine = null;
    },
    scrollToActiveLine: function() {
      if (!this.$refs.subtitleLineRefs) {
        return;
      }

      for(let i = 0; i < this.$refs.subtitleLineRefs.length; i ++) {
        const classList = Array.from(this.$refs.subtitleLineRefs[i].classList);
        if (classList.indexOf('active-subtitle') >= 0) {
          this.$refs.subtitleLineRefs[i].scrollIntoView(true);
          break;
        }
      }
    },
    toggleSubtitlesInVideo: function(enabledVideoSubtitles) {
      if (enabledVideoSubtitles) {
        this.setPlayerAsset();
      }

      if (!enabledVideoSubtitles) {
        const SETACTIVESUBTITLE = this.$store.getters.keywords.PLAYER.SETACTIVESUBTITLE;
        this.$store.commit(SETACTIVESUBTITLE, null);
      }
    },
    setPlayerAsset() {
      if (this.selectedAsset) {
        const SETACTIVESUBTITLE = this.$store.getters.keywords.PLAYER.SETACTIVESUBTITLE;
        this.$store.commit(SETACTIVESUBTITLE, this.selectedAsset);

        const REFRESHACTIVESUBTITLE = this.$store.getters.keywords.PLAYER.REFRESHACTIVESUBTITLE;
        this.$store.commit(REFRESHACTIVESUBTITLE);
      }
    }
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    subtitleAssets: function () {
      const GETSUBTITLEASSETS = this.$store.getters.keywords.SUBTITLE.GETSUBTITLEASSETS;
      return this.$store.getters[GETSUBTITLEASSETS];
    },
    selectedAsset: function () {
      const GETSELECTEDASSET = this.$store.getters.keywords.SUBTITLE.GETSELECTEDASSET;
      return this.$store.getters[GETSELECTEDASSET];
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isAudioPod: function() {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
    subtitles: function() {
      const GETSUBTITLES = this.$store.getters.keywords.SUBTITLE.GETSUBTITLES;
      return this.$store.getters[GETSUBTITLES];
    },
    filteredSubtitles: function()  {
      if (!this.search) {
        return this.subtitles;
      }
      const search = this.search.toLowerCase();
      return this.subtitles.filter(subtitle => subtitle.text.toLowerCase().includes(search));
    },
    canEditSubscriptionLine: function() {
      return function(subtitle) {
        return this.showForm && this.subtitleForEditing && (this.subtitleForEditing.id === subtitle.id);
      }
    },
    duration: function() {
      return function(subtitle) {
        const result = (subtitle.endTime - subtitle.startTime) / 1000;
        return Number(result.toFixed());
      }
    },
    isSelectedLine: function() {
      return function(subtitle) {
        return {
          "selected-line": this.subtitleForEditing && (this.subtitleForEditing.id === subtitle.id),
          "active-subtitle": this.subtitlesInVideo &&
              (
                  this.playerCurrentTime >= (subtitle.startTime / 1000)
                  &&
                  this.playerCurrentTime < (subtitle.endTime / 1000)
              ),
        };
      }
    },
    isFormShown: function() {
      return this.showForm || this.showCreationForm;
    },
    playerCurrentTime: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
  }
}
</script>

<style>
.no-selection {
  user-select: none !important;
}

.selected-line {
  background-color: #3dabff !important;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.active-subtitle {
  background-color: #3dabff;
  color: black;
  border-radius: 8px;
}

.selected-line.active-subtitle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vb-subtitles .v-input--selection-controls {
  margin: 0 !important;
}

.vb-subtitles .pod-subtitlesform-inout .v-text-field__details,
.vb-subtitles .pod-subtitlesform-inout .v-input__control {
  background-color: #3dabff !important;
}

.vb-subtitles .subtitles-form-body {
  border-color: #EBEBEB !important;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 1px 0 10px 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
}

.vb-subtitles .subtitle-assets-list {
  overflow-y: auto;
  min-height: 118px;
  max-height: 200px;
}

.theme--dark .subtitle-line {
  background-color: #2f3640;
  border-bottom: 2px solid #232b36;
  border-radius: 8px;
}

.theme--light .subtitle-line {
  background-color: #e6ecf1;
  border-bottom: 2px solid #FFFFFF;
}
</style>
