<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="nodeSideMenu-createNodes-createActivator">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-createNode-modal">
      <v-card-title class="primary d-flex justify-space-between" data-cy="dialog-createNode-title">
        {{getTitleString}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable">
        <v-form ref="form" v-on:submit.prevent="createNode" v-bind:disabled="!currentNode" style="margin-top: 16px;">

          <v-text-field
            v-model="nodeName"
            v-bind:label="$t('menus.createNode.nodeName')"
            v-bind:rules="nodeNameRules"
            v-bind:disabled="isOwnAccessPoint && !$canControlTenantConfig()"
            required block solo flat
            data-cy="dialog-createNode-nodeName">
          </v-text-field>


          <v-textarea
              v-model="nodeNote"
              v-bind:label="$t('menus.createNode.nodeNote')"
              v-bind:rules="nodeNoteRules"
              solo flat
              rows="3"
              class="media-list-notes-textarea"
              data-cy="dialog-createNode-nodeNote"
              >
          </v-textarea>

          <v-checkbox
            v-model="teamonly"
            v-bind:label="$t('routes.node.teamonly')"
            solo flat
            hide-details
            class="mt-0"
            data-cy="dialog-createNode-teamOnly"
          ></v-checkbox>

          <div v-if="isClientNode" class="mt-4">
            <v-text-field v-model="clientAddressLine1"  v-bind:label="$t('menus.createNode.addressLine1')" block solo flat data-cy="dialog-createNode-client-addr1"></v-text-field>
            <v-text-field v-model="clientAddressLine2"  v-bind:label="$t('menus.createNode.addressLine2')" block solo flat data-cy="dialog-createNode-client-addr2"></v-text-field>
            <v-text-field v-model="clientZipCode"       v-bind:label="$t('menus.createNode.zipCode')"      block solo flat data-cy="dialog-createNode-client-zipCode"></v-text-field>
            <v-text-field v-model="clientCity"          v-bind:label="$t('menus.createNode.city')"         block solo flat data-cy="dialog-createNode-client-city"></v-text-field>
            <v-select
                v-model="clientCountry"
                v-bind:items="availableCountries"
                item-text="country"
                item-value="country"
                v-bind:label="$t('menus.createNode.country')"
                block
                solo flat
                data-cy="dialog-createNode-client-selectCountry"
            ></v-select>
            <v-text-field v-model="clientWebsite"       v-bind:label="$t('menus.createNode.websiteURL')" v-bind:rules="clientWebsiteRules" block solo flat data-cy="dialog-createNode-client-website"></v-text-field>

            <div class="mb-2 text-center">
              <v-chip v-if="selectedLogoError" small class="error mb-2">
                <v-btn icon x-small v-on:click="selectedLogoError = ''" data-cy="dialog-createNode-client-logo-closeOnErr-btn">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                {{selectedLogoError}}
              </v-chip>

              <v-chip v-if="selectedLogo" small class="mb-2" data-cy="dialog-createNode-client-logo-name">
                <v-btn icon x-small v-on:click="clearSelectedLogo" data-cy="dialog-createNode-client-logo-clearSelected-btn">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                {{selectedLogo.name}}({{selectedLogo.size | prettyBytes(0, false)}})
              </v-chip>

              <div v-if="getLogoCss" v-bind:style="getLogoCss" data-cy="dialog-createNode-client-logo-showLogo">
                <v-btn icon x-small v-on:click="removeClientLogo" data-cy="dialog-createNode-client-logo-remove-btn">
                  <v-icon x-small>mdi-close</v-icon>
                </v-btn>
              </div>

              <input type="file" ref="fileInput" v-on:change="fileSelected" style="display: none" accept="image/png, image/gif" data-cy="dialog-createNode-client-logo-inputField">
              <v-btn v-if="!selectedLogo" v-on:click="selectLogoClick" elevation=0 block color="info" data-cy="dialog-createNode-client-logo-selectLogo-btn">
                {{$t('menus.createNode.selectLogo')}}
                <v-tooltip bottom z-index="1200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="" icon x-small >
                      <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('menus.createNode.selectLogoInfo')}}</span>
                </v-tooltip>
              </v-btn>
            </div>
          </div>

          <div v-if="!isClientNode && !editNodeId">
            <template>
              <div class="vb-folder-info-class">
                {{ isFolderNode ? $t('routes.node.newFolderInfo') : $t('routes.node.newProjectInfo') }} <a :href="desitnationNodePath" target="_blank" >{{ desitnationNodeName }}</a>
              </div>
            </template>
          </div>

          <div v-if="successMessage" class="success" data-cy="dialog-createNode-successMessage">
            {{successMessage}}
          </div>

          <div v-if="errorMessage" class="error" data-cy="dialog-createNode-errorMessage">
            {{errorMessage}}
          </div>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="preventDoubleClick"
          @click="createNode"
          color="primary"
          elevation="0"
          data-cy="dialog-createNode-createButton"
        >
        {{getTitleString}}
        </v-btn>
        <v-btn
          text
          @click="dialog = false"
          data-cy="dialog-createNode-closeButton"
        >
          {{$t('common.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import AllCountries from "./countries.json"
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "CreateNodeDialog",
  props: {
    nodeType: {
      type: Number,
      required: true,
    },
    parentNode: {
      type: Object,
      required: false,
    },
    editNodeId: {
      type: String,
      required: false,
    }
  },
  data() {
    const websiteRE = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    return {
      dialog: false,

      successMessage: '',   // not used yet
      errorMessage: '',

      nodeName: "",
      nodeNameRules: [
        v => !!v || this.$t('menus.createNode.nodeNameRequired'),
      ],

      nodeNote: "",
      nodeNoteRules: [
      ],

      teamonly: false,
      preventDoubleClick: false,

      availableCountries: AllCountries,

      clientAddressLine1: "",
      clientAddressLine2: "",
      clientZipCode: "",
      clientCity: "",
      clientCountry: "",
      clientWebsite: "",
      clientWebsiteRules: [
        v => (v=="" || v==null || typeof v == undefined || websiteRE.test(v)) || this.$t('menus.createNode.websiteValid'),
      ],
      selectedLogo: null,
      selectedLogoError: '',
    };
  },
  mounted: function() {
    this.initializeForm();
  },
  watch: {
    dialog: function(newValue, oldValue) {
      // clean-up the form on close
      if (oldValue) {
        this.$refs.form.reset();

        if (this.isClientNode) {
          this.$refs.fileInput.value = null;
          this.selectedLogo = null;
          this.selectedLogoError = '';
        }
      }

      // on open get the node info for edit
      if (newValue && this.editNode) {
        let self = this;
        const GETNODEINFO = this.$store.getters.keywords.NODE.GETNODEINFO;
        this.$store.dispatch(GETNODEINFO, this.editNode.id)
        .then(function() {
          self.initializeForm();
          // self.$nextTick(() => {
          //   self.initializeForm();
          // });
        })
      }
      // on open prepare the form to create
      else if (newValue) {
        this.initializeForm();
        this.errorMessage = "";
        this.successMessage = "";
        console.log("this.editNode", this.editNode)
      }
    },
  },
  methods: {
    initializeForm: function() {
      if (this.editNode) {
        this.nodeName = this.editNode.name;
        this.nodeNote = this.editNode.note;
        this.teamonly = this.editNode.teamonly;
        this.selectedNodeType = this.editNode.type;
        this.clientAddressLine1 = this.editNode.clientaddressline1;
        this.clientAddressLine2 = this.editNode.clientaddressline2;
        this.clientZipCode = this.editNode.clientzipcode;
        this.clientCity = this.editNode.clientcity;
        this.clientCountry = this.editNode.clientcountry;
        this.clientWebsite = this.editNode.clientwebsite;
      } else {
        this.teamonly = (this.nodeType === this.nodeTypes.PROJECT);
      }
    },
    createNode() {
      this.errorMessage = '';
      this.successMessage = '';

      const validForm = this.$refs.form.validate();
      if (!validForm || !this.currentNode) {
        return;
      }

      if (!this.editNode && !this.parentNode) {
        return;
      }

      let formData = {
        name: this.nodeName,
        note: this.nodeNote,
        teamonly: Boolean(this.teamonly),
        parentid: this.editNode ? this.editNode.parentid : this.parentNode.id,
        type: this.nodeType,
      };

      if (this.isClientNode) {
        formData.clientaddressline1 = this.clientAddressLine1;
        formData.clientaddressline2 = this.clientAddressLine2;
        formData.clientzipcode = this.clientZipCode;
        formData.clientcity = this.clientCity;
        formData.clientcountry = this.clientCountry;
        formData.clientwebsite = this.clientWebsite;
        formData.logo = this.selectedLogo ? this.selectedLogo : null;
      }

      if (this.editNode) {
        formData.id = this.editNode.id;
      }

      let self = this;
      let VERB = this.editNode ?
            this.$store.getters.keywords.NODE.UPDATENODE :
            this.$store.getters.keywords.NODE.CREATENODE;


      if (this.editNode && (this.editNode.type === this.nodeTypes.FOLDER)) {
        VERB = this.$store.getters.keywords.NODE.UPDATEFOLDER;
      }

      this.preventDoubleClick = true;
      this.$store.dispatch(VERB, formData)
      .then(function(result) {
        self.dialog = false;
        self.preventDoubleClick = false;

        const routePath = `/node/${result.id}`;
        if (!self.editNode && (self.$route.path !== routePath)) {
          self.$router.push(routePath);
        }

        if (self.editNode) {
          self.$showMessage(self.$t('menus.createNode.successMessageUpdate'));
        }
      })
      .catch(function(error) {
        self.preventDoubleClick = false;
        let errorMessage = error?.response?.data?.error?.message;
        if (errorMessage) {
          self.errorMessage = errorMessage;
        } else {
          self.errorMessage = 'Error';
          console.error(error); // unhandled error
        }
      });
    },
    selectLogoClick: function() {
      // console.log("Select logo clicked");
      this.$refs.fileInput.click();
    },
    fileSelected: function(e) {
      // console.log("File selected", e.target.files[0]);
      this.selectedLogo = null;
      this.selectedLogoError = "";

      if (e.target.files && e.target.files[0]) {
        if (e.target.files[0].size > 2097152) {
          this.selectedLogoError = this.$t('menus.createNode.theLogoIsTooLarge');
        } else {
          this.selectedLogo = e.target.files[0];
        }
      }

      this.$refs.fileInput.value = null;
    },
    clearSelectedLogo: function() {
      this.selectedLogo = null;
      this.selectedLogoError = "";
    },
    removeClientLogo: function() {
      const DELETELOGO = this.$store.getters.keywords.NODE.DELETELOGO;
      this.$store.dispatch(DELETELOGO, this.editNode.id);
    }
  },
  computed: {
    currentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE]
    },
    desitnationNodeName: function() {
      if (this.parentNode) {
        return this.parentNode.name;
      }

      if (this.currentNode) {
        return this.currentNode.name;
      }

      return '';
    },
    desitnationNodePath: function() {
      if (this.parentNode && this.parentNode.id) {
        return `/node/${this.parentNode.id}`;
      }

      if (this.currentNode && this.currentNode.id) {
        return `/node/${this.currentNode.id}`;
      }

      return '#';
    },
    editNode: function() {
      const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
      return this.$store.getters[GETNODEBYID](this.editNodeId);
    },
    nodeTypes: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      return this.$store.getters[NODETYPES];
    },
    isClientNode() {
      return this.nodeType === this.nodeTypes.CLIENT;
    },
    isProjectNode() {
      return this.nodeType === this.nodeTypes.PROJECT;
    },
    isFolderNode() {
      return this.nodeType === this.nodeTypes.FOLDER;
    },
    getTitleString() {
      if (this.editNode) {
        if (this.isClientNode) return this.$t('menus.createNode.updateClient');
        if (this.isProjectNode) return this.$t('menus.createNode.updateProject');
        return this.$t('menus.createNode.updateFolder');
      }

      if (this.isClientNode) return this.$t('menus.createNode.createClient');
      if (this.isProjectNode) return this.$t('menus.createNode.createProject');
      return this.$t('menus.createNode.createFolder');
    },
    getLogoCss: function() {
      if (this.editNode && this.editNode.clientlogourl) {
        return {
          width: '150px',
          height: '150px',
          margin: 'auto',
          marginBottom: '12px',
          textAlign: 'right',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundImage: 'url("'+this.editNode.clientlogourl+'")',
        };
      }

      return false;
    },
    isOwnAccessPoint: function() {
      const GETACCESSPOINTIDS = this.$store.getters.keywords.AUTH.GETACCESSPOINTIDS;
      const accessPointIds =  this.$store.getters[GETACCESSPOINTIDS];

      if (this.editNode) {
        const nodeId = this.editNode.id;
        return accessPointIds.includes(nodeId);
      }

      return false;
    },
  },
}
</script>

<style>
.vb-folder-info-class {
  margin-top: 10px;
  padding: 10px 0 10px 5px;
  font-style: italic;
}
</style>
