// import { Amplify, API, Auth } from 'aws-amplify';
// import { Amplify } from 'aws-amplify';
// import { fetchAuthSession } from 'aws-amplify/auth';

import PodCalls from './modules/PodCalls'
import NodeCalls from './modules/NodeCalls'
import UserCalls from './modules/UserCalls'
import UsageCalls from './modules/UsageCalls'
import SystemCalls from './modules/SystemCalls'
import SearchCalls from './modules/SearchCalls'
import CommonCalls from './modules/CommonCalls'
import ServiceCalls from './modules/ServiceCalls'
import AccesslinkCalls from './modules/AccesslinkCalls'
import UploadLinkCalls from './modules/UploadLinkCalls'
import PodCommentCalls from './modules/PodCommentCalls'
import PodTranscriptCalls from './modules/PodTranscriptCalls'
import NotificationCalls from './modules/NotificationCalls'
import PodLabelCalls from './modules/PodLabelCalls'
import ReportCalls from './modules/ReportCalls'
import EmbedMediaCalls from './modules/EmbedMediaCalls'
import ReviewerCalls from './modules/ReviewerCalls'
import AccountCalls from './modules/AccountCalls'
import SubtitleCalls from './modules/SubtitleCalls'
import TenantCalls from './modules/TenantCalls'
import LabelCalls from './modules/LabelCalls'




class Api {
  static pod = PodCalls;
  static node = NodeCalls;
  static user = UserCalls;
  static usage = UsageCalls;
  static system = SystemCalls;
  static search = SearchCalls;
  static common = CommonCalls;
  static service = ServiceCalls;
  static comment = PodCommentCalls;
  static transcript = PodTranscriptCalls;
  static accesslink = AccesslinkCalls;
  static uploadlink = UploadLinkCalls;
  static notification = NotificationCalls;
  static podlabel = PodLabelCalls;
  static label = LabelCalls;
  static report = ReportCalls;
  static embedmedia = EmbedMediaCalls;
  static reviewer = ReviewerCalls;
  static account = AccountCalls;
  static subtitle = SubtitleCalls;
  static tenant = TenantCalls;

  static entryPointHeader = null;

  static setEntryPoint(directoryID) {
    this.entryPointHeader = directoryID ? directoryID.toString() : null;
  }

  static configure() {

  }

  // static configure() {
  //   const existingConfig = Amplify.getConfig();

  //   Amplify.configure({
  //     ...existingConfig,
  //     API: {
  //       ...existingConfig.API,
  //       REST: {
  //         ...existingConfig.API?.REST,
  //         app: {
  //           endpoint: baseUrl + '/api/v1/',
  //           // region: 'us-east-1' // Optional
  //         },
  //       },
  //     }
  //   });

  //   const existingConfig2 = Amplify.getConfig();
  //   Amplify.configure(existingConfig2, {
  //     API: {
  //       REST: {
  //         headers: async () => {
  //           let headers = {};

  //           try {
  //             let session = await fetchAuthSession();
  //             if (session && session.tokens) {
  //               headers['id-token'] = session.tokens.idToken.toString();
  //               headers['access-token'] = session.tokens.accessToken.toString();
  //             }

  //           } catch (error) {
  //             return {};
  //           }

  //           //   if (this.entryPointHeader) {
  //           //     headers['entry-point-id'] = this.entryPointHeader;
  //           //   }

  //           return headers;
  //         },
  //       }
  //     }
  //   });
  // }
}

// Api.configure();

export default Api;
