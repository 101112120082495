<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :disabled="isDisabled"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card v-if="noThingsSelected">
      <v-card-title class="primary d-flex justify-space-between">
        {{$t('routes.node.downloadDialogNoFiles')}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable">
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{$t('routes.node.downloadDialogOK')}}</v-btn>
      </v-card-actions>
    </v-card>


    <v-card v-else>
      <v-card-title class="primary d-flex justify-space-between">
        {{$t('routes.node.downloadAssetsTitle')}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="body-1 mt-2">{{$t('routes.node.downloadDialogMessage')}}</div>

        <v-checkbox
          v-if="allowExclude && !hasSelectedAssets"
          v-model="excludeAssets"
          v-bind:label="$t('routes.node.downloadDialogExclude')"
          hide-details
        >
        </v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="downloadThings">{{$t('common.download')}}</v-btn>
        <v-btn text @click="dialog = false">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "DownloadDialog",
  props: {
    nodes: {
      type: Array,
      required: false,
    },
    pods: {
      type: Array,
      required: false
    },
    assets: {
      type: Array,
      required: false
    },
    allowExclude: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      dialog: false,
      excludeAssets: false,
    }
  },
  mounted: function() {
    this.excludeAssets = false;
  },
  methods: {
    downloadThings: function() {

      // download single raw file
      if ((!this.nodes || this.nodes.length == 0) && this.pods && this.pods.length == 1 && this.excludeAssets) {
        if (this.pods[0].asset && this.pods[0].asset.raw && this.pods[0].asset.raw[0] && this.pods[0].asset.raw[0].url) {
          this.dialog = false;
          window.location = this.pods[0].asset.raw[0].url;
          return;
        }
      }

      // download zip archive
      let payload = {
        nodeids: (this.nodes && this.nodes.length > 0) ? this.nodes.map(x => x.id) : [],
        podids: (this.pods && this.pods.length > 0) ? this.pods.map(i => i.id) : [],
        assetids: (this.assets && this.assets.length > 0) ? this.assets.map(i => i.id) : [],
        excludeAssets: this.hasSelectedAssets ? false : this.excludeAssets,
      };

      let self = this;
      const DOWNLOADTHINGS = this.$store.getters.keywords.COMMON.DOWNLOADTHINGS;
      const GETCURRENTSESSION = this.$store.getters.keywords.AUTH.GETCURRENTSESSION;

      this.$store.dispatch(GETCURRENTSESSION)
      .then(function(session) {
        self.dialog = false;
        payload['id-token'] = session.tokens.idToken.toString();
        return self.$store.dispatch(DOWNLOADTHINGS, payload);
      });
    }
  },
  computed: {
    noThingsSelected: function() {
      return (
        (!this.nodes || this.nodes.length == 0) &&
        (!this.pods || this.pods.length == 0) &&
        (!this.assets || this.assets.length == 0)
      );
    },
    hasSelectedAssets: function() {
      return this.assets && this.assets.length > 0;
    }
  }
}
</script>

<style>

</style>
