<template>
  <v-menu
    v-if="hasAnyItemsInMenu && asset"
    v-model="showingMenu"
    :position-x="positionX"
    :position-y="positionY"
    :close-on-content-click="false"
    close-on-click
    absolute
    z-index="4"
    offset-y bottom
  >

    <!-- <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot default></slot>
      </div>
    </template> -->

    <v-list data-cy="podFiles-tree-actions-list">

      <v-list-item
          v-if="!$isAdobeExtension() && !$isFCPExtension() && $canDownloadNodeAndPod() && asset.state === assetStates.COMPLETE"
          @click="downloadFile(asset)"
          data-cy="podFiles-tree-actions-downloadAsset">
        <v-list-item-icon>
          <v-icon small>mdi-download-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('routes.pod.downloadAsset')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <FinalCutProAssetDialog v-if="$isFCPExtension() && isSupportedByFCPAsset" v-bind:assets="[asset]">
        <v-list-item @click="stub">
          <v-list-item-icon>
            <v-icon small>mdi-download-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t('routes.node.FCPAdd')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </FinalCutProAssetDialog>

      <FCPSubtitleDialog v-if="$isFCPExtension() && isSubtitleAsset" v-bind:assets="[asset]">
        <v-list-item @click="stub">
          <v-list-item-icon>
            <v-icon small>mdi-download-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t('routes.node.FCPAdd')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </FCPSubtitleDialog>

      <AdobePremiereAssetDialog v-if="$isAdobeExtension()" v-bind:assets="[asset]">
        <v-list-item @click="stub">
          <v-list-item-icon>
            <v-icon small>mdi-download-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.node.adobePremiereAdd')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </AdobePremiereAssetDialog>

      <RenameAssetDialog v-if="$canControlNodeAndPod() && asset.state === assetStates.COMPLETE" v-bind:pod="pod" v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-renameAsset">
          <v-list-item-icon>
            <v-icon small>mdi-form-textbox</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.renameAsset')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </RenameAssetDialog>

      <DeletePodDialog v-if="$canControlNodeAndPod()" v-bind:pod="pod" v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-deleteAsset">
          <v-list-item-icon>
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.deleteAsset')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </DeletePodDialog>

      <!-- Divider -->
      <v-divider ></v-divider>

      <AssetLanguageDialog v-if="$canControlNodeAndPod()" v-bind:pod="pod" v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-setAssetLanguage">
          <v-list-item-icon>
            <v-icon small>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.setAssetLanguage')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </AssetLanguageDialog>

      <ConvertSubtitleDialog v-if="$canControlNodeAndPod() && isVTT" v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-convertToSrt">
          <v-list-item-icon>
            <v-icon small>mdi-file-restore-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.convertSubtitleToSRT')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ConvertSubtitleDialog>

      <ConvertSubtitleDialog v-if="$canControlNodeAndPod() && isSRT " v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-convertToVtt">
          <v-list-item-icon>
            <v-icon small>mdi-file-restore-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.convertSubtitleToVTT')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ConvertSubtitleDialog>

      <ExportAssetDialog v-if="$canControlNodeAndPod() && allowExportToPod && asset.state === assetStates.COMPLETE" v-bind:pod="pod" v-bind:asset="asset">
        <v-list-item @click="stub" data-cy="podFiles-tree-actions-exportAsset">
          <v-list-item-icon>
            <v-icon small>mdi-export</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('routes.pod.exportAsset')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ExportAssetDialog>

    </v-list>

  </v-menu>
</template>

<script>
import MimetypeHelper from '../../../../../components/MimetypeHelper.js'

import AdobePremiereAssetDialog from './dialogs/AdobePremiereAssetDialog.vue'
import FinalCutProAssetDialog from './dialogs/FinalCutProAssetDialog.vue'
import FCPSubtitleDialog from './dialogs/FCPSubtitleDialog.vue'
import RenameAssetDialog from './dialogs/RenameAssetDialog.vue'
import DeletePodDialog from '../../../../../routes/nodes2/components/dialogs/DeletePodDialog.vue'
import AssetLanguageDialog from './dialogs/AssetLanguageDialog.vue'
import ConvertSubtitleDialog from './dialogs/ConvertSubtitleDialog.vue'
import ExportAssetDialog from './dialogs/ExportAssetDialog.vue'

export default {
  name: "AssetsContextMenu",
  components: {
    AdobePremiereAssetDialog,
    FinalCutProAssetDialog,
    FCPSubtitleDialog,
    RenameAssetDialog,
    DeletePodDialog,
    AssetLanguageDialog,
    ConvertSubtitleDialog,
    ExportAssetDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    pod: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      // required: true,
    },
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      // showingMenu: false,
    };
  },
  methods: {
    downloadFile: function(asset) {
      // console.log("Download", item);
      if(this.pod.archived) return;

      this.showingMenu = false;
      window.location = asset.url;
    },
    stub: function() {
      this.showingMenu = false;
    },
  },
  computed: {
    showingMenu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasAnyItemsInMenu: function() {
      return this.$canControlNodeAndPod() ||
              this.$canDownloadNodeAndPod();
    },
    assetStates: function() {
      const ASSETSTATES = this.$store.getters.keywords.COMMON.ASSETSTATES;
      return this.$store.getters[ASSETSTATES];
    },
    assetTypes: function() {
      const ASSETTYPES = this.$store.getters.keywords.COMMON.ASSETTYPES;
      return this.$store.getters[ASSETTYPES];
    },
    isSRT: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      const states = this.$store.getters[PODSTATES];
      return !this.asset.broken && this.asset.state === states.COMPLETE && this.asset.type === this.assetTypes.SRT;
    },
    isVTT: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      const states = this.$store.getters[PODSTATES];
      return !this.asset.broken && this.asset.state === states.COMPLETE && this.asset.type === this.assetTypes.VTT;
    },
    isSupportedByFCPAsset: function() {
      return MimetypeHelper.isSupportedByFCPAsset(this.asset);
    },
    isSubtitleAsset: function() {
      // return this.subtitleAssets.includes(this.asset);
      let subsAssets = [this.assetTypes.VTT, this.assetTypes.SRT];
      return subsAssets.indexOf(this.asset.type) >= 0;
    },
    allowExportToPod: function() {
      return true; // In 11824 we remove the restriction for exporting assests to pods

      // const allowedTypes = [
      //     this.assetTypes.THUMBNAIL, this.assetTypes.LIVEPREVIEW,
      //     this.assetTypes.FRAME, this.assetTypes.RAW,
      //     this.assetTypes.AUDIO, this.assetTypes.WEB, this.assetTypes.EXTERNAL,
      //     this.assetTypes.IMAGE, this.assetTypes.VIDEO,
      // ];

      // return allowedTypes.indexOf(this.item.type) >= 0;
    },


  },
}
</script>

<style>
</style>